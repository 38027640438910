/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';

/**
 * A class representing static constant and read only values
 * for claim based business logic.
 *
 * @export
 * @class ClaimConstants
 */
export class ClaimConstants
{
	/**
	 * Gets or sets the set of claims
	 * entity types available in this module.
	 *
	 * @type {any}
	 * @memberof ClaimConstants
	 */
	public static readonly claimEntityTypes:
	{
		claim: string;
		noteClaim: string;
		claimPolicy: string;
		claimPayment: string;
		claimProduct: string;
	} = {
			claim: 'Claim',
			noteClaim: 'Note.Claim',
			claimPolicy: 'ClaimPolicy',
			claimPayment: 'ClaimPayment',
			claimProduct: 'ClaimProduct'
		};

	/**
	 * Gets or sets the set of claims
	 * entity type groups available in this module.
	 *
	 * @type {any}
	 * @memberof ClaimConstants
	 */
	public static readonly claimEntityTypeGroups:
	{
		claims: string;
		noteClaims: string;
		claimPolicies: string;
		claimPayments: string;
		claimProducts: string;
	} = {
			claims: 'Claims',
			noteClaims: 'Note.Claims',
			claimPolicies: 'ClaimPolicies',
			claimPayments: 'ClaimPayments',
			claimProducts: 'ClaimProducts'
		};

	/**
	 * Gets or sets the set of claims service provider entity types.
	 *
	 * @type {any}
	 * @memberof ClaimConstants
	 */
	public static readonly serviceProviderEntityTypes:
	{
		insuranceCompany: string;
		adjustingCompany: string;
		vendorCompany: string;
		thirdPartyAdministrator: string;
	} = {
			insuranceCompany:
				InsuranceConstants.insuranceEntityTypes
					.insuranceCompany,
			adjustingCompany:
				InsuranceConstants.insuranceEntityTypes
					.independentAdjustingCompany,
			vendorCompany:
				InsuranceConstants.insuranceEntityTypes
					.claimsVendor,
			thirdPartyAdministrator:
				InsuranceConstants.insuranceEntityTypes
					.thirdPartyAdministrator,
		};

	/**
	 * Gets or sets the set of claims service provider entity type groups.
	 *
	 * @type {any}
	 * @memberof ClaimConstants
	 */
	public static readonly serviceProviderEntityTypeGroups:
	{
		insuranceCompany: string;
		adjustingCompany: string;
		vendorCompany: string;
		thirdPartyAdministrator: string;
	} = {
			insuranceCompany:
				InsuranceConstants.insuranceEntityTypeGroups
					.insuranceCompanies,
			adjustingCompany:
				InsuranceConstants.insuranceEntityTypeGroups
					.independentAdjustingCompanies,
			vendorCompany:
				InsuranceConstants.insuranceEntityTypeGroups
					.claimsVendors,
			thirdPartyAdministrator:
				InsuranceConstants.insuranceEntityTypeGroups
					.thirdPartyAdministrators,
		};

	/**
	 * Gets or sets the set of key activities.
	 *
	 * @type {any}
	 * @memberof ClaimConstants
	 */
	public static readonly keyActivities:
	{
		claimOwnerAssigned: string;
		exposureAdded: string;
		exposureClosed: string;
		exposureReopened: string;
		payment: string;
	} = {
			claimOwnerAssigned: 'Claim Owner Assigned',
			exposureAdded: 'Exposure Added',
			exposureClosed: 'Exposure Closed',
			exposureReopened: 'Exposure Reopened',
			payment: 'Payment'
		};

	/**
	 * Gets or sets the set of claims ledger entity types.
	 *
	 * @type {any}
	 * @memberof ClaimConstants
	 */
	public static readonly ledgerEntityTypes:
	{
		ledger: string;
		ledgerTransactionClaim: string;
	} = {
			ledger: 'Ledger',
			ledgerTransactionClaim: 'LedgerTransaction.Claim'
		};

	/**
	 * Gets or sets the set of claims ledger entity type groups.
	 *
	 * @type {any}
	 * @memberof ClaimConstants
	 */
	public static readonly ledgerEntityTypeGroups:
	{
		ledgers: string;
		ledgerTransactionClaims: string;
	} = {
			ledgers: 'Ledgers',
			ledgerTransactionClaims: 'LedgerTransaction.Claims'
		};

	/**
	 * Gets or sets the ledger transction claim types.
	 *
	 * @type {any}
	 * @memberof ClaimConstants
	 */
	public static readonly ledgerTransactionClaimTypes:
	{
		loss: string;
		adjustingAndOther: string;
		defenseAndCostContainment: string;
	} = {
			loss: 'Loss',
			adjustingAndOther: 'AdjustingAndOther',
			defenseAndCostContainment: 'DefenseAndCostContainment'
		};

	/**
	 * Gets or sets the available claims specific workflow actions.
	 *
	 * @type {any}
	 * @memberof ClaimConstants
	 */
	public static readonly workflowActions:
	{
		addExposure: string;
		adjustReserves: string;
		createClaim: string;
		verifyCoverage: string;
		manageExposureParties: string;
		manageAdditionalPayees: string;
		rejectClaimPayment: string;
		approveClaimPayment: string;
		closeClaimExposure: string;
		reopenClaimExposure: string;
		closeClaim: string;
		reopenClaim: string;
		assignClaimOwner: string;
		manageClaimant: string;
		manageAdjuster: string;
		manageVendor: string;
	} = {
			addExposure: 'AddExposure',
			adjustReserves: 'AdjustReserves',
			createClaim: 'CreateClaim',
			verifyCoverage: 'VerifyCoverage',
			manageExposureParties: 'ManageExposureParties',
			manageAdditionalPayees: 'ManageAdditionalPayees',
			rejectClaimPayment:'RejectClaimPayment',
			approveClaimPayment: 'ApproveClaimPayment',
			closeClaimExposure: 'CloseClaimExposure',
			reopenClaimExposure: 'ReopenClaimExposure',
			closeClaim: 'CloseClaim',
			reopenClaim: 'ReopenClaim',
			assignClaimOwner: 'AssignClaimOwner',
			manageClaimant: 'ManageClaimant',
			manageAdjuster: 'ManageAdjuster',
			manageVendor: 'ManageVendor'
		};

	/**
	 * Gets the entity type groups used in the claims module.
	 *
	 * @type {string[]}
	 * @memberof ClaimConstants
	 */
	public static readonly claimsModuleEntityTypeGroups: string[] =
		[
			this.claimEntityTypeGroups.claims,
			this.claimEntityTypeGroups.claimPolicies,
			this.claimEntityTypeGroups.claimPayments,
			this.claimEntityTypeGroups.noteClaims
		];

	/**
	 * Gets or sets the reason types.
	 *
	 * @type {any}
	 * @memberof ClaimConstants
	 */
	public static readonly reasonType:
	{
		open: string;
		close: string;
	} = {
			open: 'Open',
			close: 'Close'
		};
}