/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	Location
} from '@angular/common';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	SecurityRightCategory
} from '@shared/constants/enums/security-right-category.enum';
import {
	SecurityRightType
} from '@shared/constants/enums/security-right-type.enum';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	DrawerListDirective
} from '@shared/directives/drawer-list-directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	ISecureMenuItem
} from '@shared/interfaces/secure-menu-item.interface';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';
@Component({
	selector: 'app-policy-details',
	templateUrl: './policy-details.component.html',
	styleUrls: [
		'./policy-details.component.scss'
	]
})

/**
 * A component representing context level policy details handling.
 *
 * @export
 * @class PolicyDetailsComponent
 * @extends {DrawerListDirective<IEntityInstance>}
 * @implements {IDynamicComponent<Component, any>}
 * @implements {IOwnershipGuardComponent}
 */
export class PolicyDetailsComponent
	extends DrawerListDirective<IEntityInstance>
	implements OnInit, IDynamicComponent<Component, any>,
		IOwnershipGuardComponent
{
	/**
	 * Initializes a new instance of the policy details component.
	 *
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @param {Location} location
	 * The location object.
	 * @param {Location} resolver
	 * The resolver object.
	 * @memberof PolicyDetailsComponent
	 */
	public constructor(
		public router: Router,
		public entityInstanceApiService: EntityInstanceApiService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public sessionService: SessionService,
		public location: Location,
		public resolver: ResolverService)
	{
		super();
	}

	/**
	 * Gets or sets list of required resources.
	 *
	 * @type {boolean}
	 * @memberof PolicyDetailsComponent
	 */
	public isOwnershipAllowed: boolean = true;

	/**
	 * Gets or sets the session identifier.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	public sessionIdentifier: string = AppConstants.empty;

	/**
	 * Gets or sets the access denied url.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	public accessDeniedUrl: string = AppConstants.empty;

	/**
	 * Gets or sets list of required resources.
	 *
	 * @type {string[]}
	 * @memberof PolicyDetailsComponent
	 */
	public resources: string[] = [];

	/**
	 * Gets or sets the client message if insufficient resources exist.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	public clientMessage: string = AppConstants.empty;

	/**
	 * Gets or sets the menu items.
	 *
	 * @type {ISecureMenuItem[]}
	 * @memberof PolicyDetailsComponent
	 */
	public tabItems: ISecureMenuItem[];

	/**
	 * Gets or sets the child entity type filter.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	public wildcardChildFilter: string
		= ClaimConstants.claimEntityTypes.claimPolicy;

	/**
	 * Sets the summary tab string value.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	public readonly summaryTab: string = 'summary';

	/**
	 * Sets the coverages tab string value.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	public readonly coveragesTab: string = 'coverages';

	/**
	 * Sets the interests tab string value.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	public readonly interestsTab: string = 'interests';

	/**
	 * Sets the incidents tab string value.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	public readonly incidentsTab: string = 'incidents';

	/**
	 * Sets the tab to display.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	public tab: string = this.summaryTab;

	/**
	 * Sets the loading boolean.
	 *
	 * @type {boolean}
	 * @memberof PolicyDetailsComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the summary layout used in implementing component.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof PolicyDetailsComponent
	 */
	public dynamicFormlyLayoutSummary: FormlyFieldConfig[];

	/**
	 * Gets or sets the coverages layout used in implementing component.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof PolicyDetailsComponent
	 */
	public dynamicFormlyLayoutCoverages: FormlyFieldConfig[];

	/**
	 * Gets or sets the interests layout used in implementing component.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof PolicyDetailsComponent
	 */
	public dynamicFormlyLayoutInterests: FormlyFieldConfig[];

	/**
	 * Gets or sets the incidents layout used in implementing component.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof PolicyDetailsComponent
	 */
	public dynamicFormlyLayoutIncidents: FormlyFieldConfig[];

	/**
	 * Gets or sets the claim policy instance data.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof PolicyDetailsComponent
	 */
	private claimPolicy: IEntityInstance[] = [];

	/**
	 * Gets or sets the parent policy instance data.
	 *
	 * @type {IEntityInstance}
	 * @memberof PolicyDetailsComponent
	 */
	private parentPolicy: IEntityInstance;

	/**
	 * Gets or sets the ui-g-12 div and class.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	private readonly uig12ClassDiv: string = '<div class=\"ui-g-12\">';

	/**
	 * Gets or sets the summary data labels div and class.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	private readonly dataLabelsSummaryClassDiv: string =
		'<div class=\"data-labels-summary ui-g-9 no-padding\">';

	/**
	 * Gets or sets the summary data set div and class.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	private readonly dataSetSummaryClassDiv: string =
		'<div class=\"data-set-summary ui-g-3 no-padding\">';

	/**
	 * Gets or sets the coverages data labels div and class.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	private readonly dataLabelsCoveragesClassDiv: string =
		'<div class=\"data-labels-coverages ui-g-9 no-padding\">';

	/**
	 * Gets or sets the coverages data set div and class.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	private readonly dataSetCoveragesClassDiv: string =
		'<div class=\"data-set-coverages ui-g-3 no-padding\">';

	/**
	 * Gets or sets the ellipsis text div and class.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	private readonly ellipsisClassDiv: string =
		'<div class=\"ellipsis-text data-height\">';

	/**
	 * Gets or sets the classes for the clickable policy number link.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	private readonly clickablePolicyNumberClass: string =
		'banner-text-link text-link';

	/**
	 * Gets or sets empty span tag.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	private readonly emptyClassSpan: string =
		'<span>';

	/**
	 * Gets or sets the html div closing.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	private readonly divEnd: string = '</div>';

	/**
	 * Gets or sets the html span closing.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	private readonly spanEnd: string = '</span>';

	/**
	 * Gets or sets the markdown table separator.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	private readonly tableSeparator: string = '|-------|-------|\n';

	/**
	 * Gets or sets the markdown asset table separator.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	private readonly assetTableSeparator: string = '|------|------|\n';

	/**
	 * Gets or sets the clickable-policy-number span and class.
	 *
	 * @type {string}
	 * @memberof PolicyDetailsComponent
	 */
	private get clickableLinkClassSpan(): string {
		return `<span class="${this.clickablePolicyNumberClass}">`;
	}

	/**
	 * Handles the on initialization event.
	 * This method will set configurable properties used in the drawer list
	 * directive and this component's view.
	 *
	 * @async
	 * @memberof PolicyDetailsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		if (!await this.isPageOwnershipAllowed())
		{
			this.accessDeniedUrl = this.location.path();
			this.sessionIdentifier = this.sessionService.sessionId;
			this.resources =
				[
					ClaimConstants.claimEntityTypes.claim
				];
			this.clientMessage =
				'Access is required to at least one claim '
					+ 'entity type and version.';
			this.isOwnershipAllowed = false;

			this.loading = false;

			return;
		}

		const entityInstanceComponent: EntityInstanceComponent =
			<EntityInstanceComponent>this.context.source;

		await this.setSecurityDefinitions(
			entityInstanceComponent.id,
			entityInstanceComponent.entityType.group,
			entityInstanceComponent.entityDefinition,
			entityInstanceComponent.entityInstanceApiService,
			entityInstanceComponent.entityTypeApiService);

		this.setTabItems();

		await this.setClaimPolicyData();

		await this.setParentPolicy();

		if (!AnyHelper.isNullOrEmptyArray(this.claimPolicy[0]))
		{
			await this.performPostInitActions();
		}

		this.loading = false;
	}

	/**
	 * Gets a value indicating whether the view is valid.
	 *
	 * @returns {boolean}
	 * A boolean value representing if the view is valid.
	 * @memberof PolicyDetailsComponent
	 */
	public isValid(): boolean
	{
		return !AnyHelper.isNull(this.context?.source)
			&& (this.context.source instanceof EntityInstanceComponent);
	}

	/**
	* Implements the ownership guard interface.
	* This will calculate drawer ownership permissions.
	*
	* @async
	* @returns {Promise<boolean>}
	* A value signifying whether or not access is allowed to this drawer.
	* @memberof PolicyDetailsComponent
	*/
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		await this.entityService.setStoredVariables();

		const claimEntityTypes: IEntityType[] =
			this.entityService
				.entityTypes
				.filter(
					(entityType: IEntityType) =>
						entityType.group.indexOf(
							ClaimConstants.claimEntityTypes
								.claim) !== -1);

		if (claimEntityTypes.length === 0)
		{
			return false;
		}

		const initialPromiseArray: Promise<any>[] = [];
		claimEntityTypes.forEach(
			(entityType: IEntityType) =>
			{
				initialPromiseArray.push(
					this.entityService.verifyEntityTypeAccess(
						entityType));
			});

		const allowedEntities: boolean[] =
		await Promise.all(initialPromiseArray);

		return allowedEntities.some(
			(allowed: boolean) =>
				allowed === true);
	}

	/**
	 * Handles click event and calls the navigate to policy if
	 * the correct link is clicked.
	 *
	 * @async
	 * @param {MouseEvent} event
	 * The mouse event used to capture click information.
	 * @memberof PolicyDetailsComponent
	 */
	public async handleClick(
		event: MouseEvent): Promise<void>
	{
		const target = event.target as HTMLElement;
		if (target.classList.contains('banner-text-link'))
		{
			await this.navigateToPolicy(
				this.parentPolicy.id,
				this.parentPolicy.entityType);
		}
	}

	/**
	 * This will handle navigating to the parent policy.
	 *
	 * @async
	 * @param {number} entityId
	 * The policy entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof PolicyDetailsComponent
	 */
	private async navigateToPolicy(
		entityId: number,
		group: string): Promise<void>
	{
		if(!AnyHelper.isNull(this.parentPolicy))
		{
			this.router.navigate(
				[
					`${group}/entities`,
					InsuranceConstants
						.insuranceEntityTypeGroups
						.policies,
					AppConstants.viewTypes.edit,
					entityId
				],
				{
					queryParams: {
						routeData:
							ObjectHelper.mapRouteData(
								{
									layoutType:
										AppConstants.layoutTypes.full
								})
					}
				});
		}
	}

	/**
	 * Handles setting the claims parent policy data for navigation.
	 *
	 * @memberof PolicyDetailsComponent
	 */
	private async setParentPolicy(): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
		ClaimConstants.claimEntityTypeGroups.claims;

		const claimId =
			(<EntityInstanceComponent>this.context.source)
				.entityInstance.id;

		const policy =
			await this.entityInstanceApiService.getParents(
				claimId,
				AppConstants.empty,
				AppConstants.empty,
				null,
				1,
				InsuranceConstants
					.insuranceEntityTypeGroups
					.policies);

		this.parentPolicy = policy[0];
	}

	/**
	 * Checks user permissions and handles enabling/disabling
	 * clickable policy number
	 *
	 * @memberof PolicyDetailsComponent
	 */
	private hasPermission(): boolean
	{
		const currentUser: IUser =
			this.resolver.resolveCurrentUser();

		const allowedGroups =
			[
				InsuranceConstants.securityGroups.underwriterManager,
				InsuranceConstants.securityGroups.underwriter,
				InsuranceConstants.securityGroups.companyCSR
			];

		return SecurityHelper
			.membershipExists(
				allowedGroups,
				currentUser);
	}

	/**
	 * Handles setting the tabItems with their corresponding
	 * icons and commands.
	 *
	 * @memberof PolicyDetailsComponent
	 */
	private setTabItems(): void
	{
		this.tabItems = SecurityHelper.scrubMenuItems(
			[
				<ISecureMenuItem>
				{
					icon: 'fa-file-text',
					id: this.summaryTab,
					securityRightCategory: SecurityRightCategory.TopLevelRight,
					securityRightType: SecurityRightType.read,
					command:
						(event: any) =>
						{
							this.tab = this.summaryTab;
							event.stopPropagation();
						}
				},
				<ISecureMenuItem>
				{
					icon: 'fa-umbrella',
					id: this.coveragesTab,
					securityRightPath: '$.data.coverages',
					securityRightCategory: SecurityRightCategory.Data,
					securityRightType: SecurityRightType.read,
					command:
						(event: any) =>
						{
							this.tab = this.coveragesTab;
							event.stopPropagation();
						}
				},
				<ISecureMenuItem>
				{
					icon: 'fa-user',
					id: this.interestsTab,
					securityRightPath: '$.data.interests',
					securityRightCategory: SecurityRightCategory.Data,
					securityRightType: SecurityRightType.read,
					command:
						(event: any) =>
						{
							this.tab = this.interestsTab;
							event.stopPropagation();
						}
				},
				<ISecureMenuItem>
				{
					icon: 'fa-fire',
					id: this.incidentsTab,
					securityRightPath: '$.data.incidents',
					securityRightCategory: SecurityRightCategory.Data,
					securityRightType: SecurityRightType.read,
					command:
						(event: any) =>
						{
							this.tab = this.incidentsTab;
							event.stopPropagation();
						}
				}
			],
			this.wildcardChildFilter,
			this.securityDefinitions);
	}

	/**
	 * Handles setting the claimPolicy data to be displayed
	 * in the drawer.
	 *
	 * @async
	 * @memberof PolicyDetailsComponent
	 */
	private async setClaimPolicyData(): Promise<void>
	{
		const claimId =
			(<EntityInstanceComponent>this.context.source)
				.entityInstance.id;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		this.claimPolicy =
			await this.entityInstanceApiService.getChildren(
				claimId,
				AppConstants.empty,
				AppConstants.empty,
				null,
				1,
				ClaimConstants.claimEntityTypeGroups.claimPolicies);

		this.context.data.claimPolicy = this.claimPolicy[0];
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @async
	 * @memberof PolicyDetailsComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		this.setSummaryLayout();
		this.setCoveragesLayout();
		this.setInterestsLayout();
		this.setIncidentsLayout();
	}

	/**
	 * Handles the Summary layout.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @async
	 * @memberof PolicyDetailsComponent
	 */
	private async setSummaryLayout(): Promise<void>
	{
		this.dynamicFormlyLayoutSummary =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					type: FormlyConstants
						.customControls.customSectionTitle,
					props: {
						label: 'Summary'
					}
				},
				<FormlyFieldConfig>
				{
					type: FormlyConstants
						.customControls.customTextDisplay,
					props: {
						content: AppConstants.empty,
						useMarkdown: true,
						usePanelDisplay: false,
						centerText: false
					},
					expressions: {
						'props.content':
						(field: FormlyFieldConfig) =>
						{
							const effectiveDate: string =
								DateHelper.formatDate(
									DateHelper.fromUtcIso(
										field.model.data?.term?.effectiveDate
											?? AppConstants.empty),
									DateHelper.presetFormats.longDateFormat);

							const expirationDate: string =
								DateHelper.formatDate(
									DateHelper.fromUtcIso(
										field.model.data?.term?.expirationDate
											?? AppConstants.empty),
									DateHelper.presetFormats.longDateFormat);

							const policyNumberClassSpan =
								this.hasPermission()
									&& !AnyHelper.isNull(this.parentPolicy)
										&& field.model.data?.system ===
											AppConstants.systems.nautix
									? this.clickableLinkClassSpan
									: this.emptyClassSpan;

							const markdown: string =
								'<div class=\"css\">' +
								this.uig12ClassDiv
									+ this.dataLabelsSummaryClassDiv
										+ this.ellipsisClassDiv
											+ 'Source'
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ 'Policy #'
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ 'Transaction #'
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ 'Term'
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ 'Effective Date'
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ 'Expiration Date'
										+ this.divEnd
									+ this.divEnd
									+ this.dataSetSummaryClassDiv
										+ this.ellipsisClassDiv
											+ (field.model.data?.system
												?? AppConstants.empty)
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ policyNumberClassSpan
												+ (field.model.data?.policyNumber
													?? AppConstants.empty)
											+ this.spanEnd
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ (field.model.data?.transactionNumber
												?? AppConstants.empty)
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ (field.model.data?.term?.number ?? AppConstants.empty)
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ effectiveDate
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ expirationDate
										+ this.divEnd
									+ this.divEnd
								+ this.divEnd;

							return markdown;
						}
					}
				},
				<FormlyFieldConfig>
				{
					type: FormlyConstants
						.customControls.customSectionTitle,
					props: {
						label: 'Insurance Company'
					}
				},
				<FormlyFieldConfig>
				{
					type: FormlyConstants
						.customControls.customTextDisplay,
					props: {
						content: AppConstants.empty,
						useMarkdown: true,
						usePanelDisplay: false,
						centerText: false
					},
					expressions: {
						'props.content':
						(field: FormlyFieldConfig) =>
						{
							const insuranceCompanyAddress: string =
								StringHelper.toAddressString(
									field.model.data?.insuranceCompany
										?.addresses[0]?.address,
									field.model.data?.insuranceCompany
										?.addresses[0]?.city,
									field.model.data?.insuranceCompany
										?.addresses[0]?.state,
									field.model.data?.insuranceCompany
										?.addresses[0]?.postalCode);

							const insuranceCompanyPhone: string =
								field.model.data?.insuranceCompany?.phones
									?.length > 0
									? field.model.data?.insuranceCompany
										?.phones[0]?.number
										|| AppConstants.empty
									: AppConstants.empty;

							const markdown: string =
								this.uig12ClassDiv
									+ this.dataLabelsSummaryClassDiv
										+ this.ellipsisClassDiv
											+ 'Name'
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ 'Address'
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ 'Phone'
										+ this.divEnd
									+ this.divEnd
									+ this.dataSetSummaryClassDiv
										+ this.ellipsisClassDiv
											+ (field.model.data?.insuranceCompany
												?.name?.legalName ?? AppConstants.empty)
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ insuranceCompanyAddress
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ insuranceCompanyPhone
										+ this.divEnd
									+ this.divEnd
								+ this.divEnd;

							return markdown;
						}
					}
				},
				<FormlyFieldConfig>
				{
					type: FormlyConstants
						.customControls.customSectionTitle,
					props: {
						label: 'Agency'
					}
				},
				<FormlyFieldConfig>
				{
					type: FormlyConstants
						.customControls.customTextDisplay,
					props: {
						content: AppConstants.empty,
						useMarkdown: true,
						usePanelDisplay: false,
						centerText: false
					},
					expressions: {
						'props.content':
						(field: FormlyFieldConfig) =>
						{
							const agencyAddress: string =
								StringHelper.toAddressString(
									field.model.data?.agency
										?.addresses[0]?.address,
									field.model.data?.agency
										?.addresses[0]?.city,
									field.model.data?.agency
										?.addresses[0]?.state,
									field.model.data?.agency
										?.addresses[0]?.postalCode);

							const agencyPhone: string =
								field.model.data?.agency?.phones?.length > 0
									? field.model.data?.agency?.phones[0]
										?.number || AppConstants.empty
									: AppConstants.empty;

							const markdown: string =
								this.uig12ClassDiv
									+ this.dataLabelsSummaryClassDiv
										+ this.ellipsisClassDiv
											+ 'Name'
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ 'Address'
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ 'Phone'
										+ this.divEnd
									+ this.divEnd
									+ this.dataSetSummaryClassDiv
										+ this.ellipsisClassDiv
											+ (field.model.data?.agency
												?.name?.legalName ?? AppConstants.empty)
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ agencyAddress
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ agencyPhone
										+ this.divEnd
									+ this.divEnd
								+ this.divEnd;

							return markdown;
						}
					}
				},
				<FormlyFieldConfig>
				{
					type: FormlyConstants
						.customControls.customSectionTitle,
					props: {
						label: 'Producer'
					}
				},
				<FormlyFieldConfig>
				{
					type: FormlyConstants
						.customControls.customTextDisplay,
					props: {
						content: AppConstants.empty,
						useMarkdown: true,
						usePanelDisplay: false,
						centerText: false
					},
					expressions: {
						'props.content':
						(field: FormlyFieldConfig) =>
						{
							const producerName: string =
								StringHelper.toNameString(
									field.model.data?.producer
										?.name?.firstName,
									field.model.data?.producer
										?.name?.lastName);

							const producerPhone: string =
								field.model.data?.producer?.phones?.length > 0
									? field.model.data?.producer?.phones[0]
										?.number || AppConstants.empty
									: AppConstants.empty;

							const markdown: string =
								this.uig12ClassDiv
									+ this.dataLabelsSummaryClassDiv
										+ this.ellipsisClassDiv
											+ 'Name'
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ 'Phone'
										+ this.divEnd
									+ this.divEnd
									+ this.dataSetSummaryClassDiv
										+ this.ellipsisClassDiv
											+ producerName
										+ this.divEnd
										+ this.ellipsisClassDiv
											+ producerPhone
										+ this.divEnd
									+ this.divEnd
								+ this.divEnd;

							return markdown;
						}
					}
				}
			];
	}

	/**
	 * Handles the Coverages layout.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @async
	 * @memberof PolicyDetailsComponent
	 */
	private async setCoveragesLayout(): Promise<void>
	{
		this.dynamicFormlyLayoutCoverages =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					type: FormlyConstants
						.customControls.customSectionTitle,
					props: {
						label: 'Policy'
					}
				}
			];

		if (!AnyHelper.isNullOrEmptyArray(
			this.claimPolicy[0].data.coverages))
		{
			this.dynamicFormlyLayoutCoverages.push(
				await this.createMarkdownField(
					'coverages',
					'|Coverages||\n'));
		}

		if (!AnyHelper.isNullOrEmptyArray(
			this.claimPolicy[0].data.deductibles))
		{
			this.dynamicFormlyLayoutCoverages.push(
				await this.createMarkdownField(
					'deductibles',
					'|Deductibles||\n'));
		}

		if (!AnyHelper.isNullOrEmptyArray(
			this.claimPolicy[0].data.modifiers))
		{
			this.dynamicFormlyLayoutCoverages.push(
				await this.createMarkdownField(
					'modifiers',
					'|Modifiers||\n'));
		}

		if (this.dynamicFormlyLayoutCoverages.length === 1)
		{
			this.dynamicFormlyLayoutCoverages.push(
				<FormlyFieldConfig>
				{
					type: FormlyConstants
						.customControls
						.customTextDisplay,
					props: {
						content: 'No results found.',
						useMarkdown: true,
						usePanelDisplay: false,
						centerText: true
					}
				});
		}

		await this.setAssetsLayout();
	}

	/**
	 * Handles the layout for asset-related fields in the Coverages section.
	 *
	 * @async
	 * @memberof PolicyDetailsComponent
	 */
	private async setAssetsLayout(): Promise<void>
	{
		this.dynamicFormlyLayoutCoverages.push(
			<FormlyFieldConfig>
			{
				key: 'data.assets',
				type: FormlyConstants
					.customControls.customRepeater,
				defaultValue: [],
				props: {
					label: 'Assets',
					singular: 'Asset',
					titleFormat: '{type}',
					disabled: true,
					filter: AppConstants.empty,
					initialItemValue: {}
				},
				fieldArray: {
					fieldGroup: [
						<FormlyFieldConfig>
						{
							key: 'type',
							type: FormlyConstants
								.customControls.input,
							wrappers: [
								FormlyConstants
									.customControls.customFieldWrapper
							],
							props: {
								label: 'Type'
							},
							expressions: {
								hide: 'true'
							}
						}
					]
				}
			});

		const assetFieldConfig =
			this.dynamicFormlyLayoutCoverages.find(
				(fieldConfig: FormlyFieldConfig) =>
					fieldConfig.key === 'data.assets');

		const assets =
			this.claimPolicy[0].data.assets;
		assets.forEach(
			async (asset: any) =>
			{
				await this.addAssetCoverages(
					asset,
					assetFieldConfig);
				await this.addAssetDeductibles(
					asset,
					assetFieldConfig);
				await this.addAssetModifiers(
					asset,
					assetFieldConfig);

				if ((<any>assetFieldConfig.fieldArray).fieldGroup.length === 1)
				{
					(<any>assetFieldConfig.fieldArray).fieldGroup.push(
						<FormlyFieldConfig>
						{
							type: FormlyConstants
								.customControls
								.customTextDisplay,
							props: {
								content: 'No results found.',
								useMarkdown: false,
								usePanelDisplay: false,
								centerText: true
							}
						});
				}
			});
	}

	/**
	 * Adds the coverages to the asset section for the dynamic Formly layout.
	 *
	 * @async
	 * @param {any} asset
	 * The asset object containing coverage information.
	 * @param {FormlyFieldConfig} assetFieldConfig
	 * The Formly field configuration for assets.
	 * @memberof PolicyDetailsComponent
	 */
	private async addAssetCoverages(
		asset: any,
		assetFieldConfig: FormlyFieldConfig)
	{
		if (!AnyHelper.isNullOrEmptyArray(asset.coverages))
		{
			(<any>assetFieldConfig.fieldArray).fieldGroup.push(
				<FormlyFieldConfig>
				{
					key: 'coverages',
					type: FormlyConstants
						.customControls
						.customRepeater,
					defaultValue: [],
					props: {
						label: 'Asset Coverages',
						singular: 'Coverage',
						titleFormat: '{name}',
						disabled: true,
						filter: AppConstants.empty,
						initialItemValue: {}
					},
					fieldArray: {
						fieldGroup: [
							<FormlyFieldConfig>
							{
								key: 'name',
								type: FormlyConstants
									.customControls
									.input,
								wrappers: [
									FormlyConstants
										.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Coverage'
								}
							},
							<FormlyFieldConfig>
							{
								key: 'amount',
								type: FormlyConstants
									.customControls
									.input,
								wrappers: [
									FormlyConstants
										.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Amount'
								}
							}
						]
					},
					expressions: {
						hide: 'true'
					}
				},

				await this.createMarkdownField(
					'coverages',
					'|Coverages||\n',
					true));
		}
	}

	/**
	 * Adds the deductibles to the asset section for the dynamic Formly layout.
	 *
	 * @async
	 * @param {any} asset
	 * The asset object containing coverage information.
	 * @param {FormlyFieldConfig} assetFieldConfig
	 * The Formly field configuration for assets.
	 * @memberof PolicyDetailsComponent
	 */
	private async addAssetDeductibles(
		asset: any,
		assetFieldConfig: FormlyFieldConfig)
	{
		if (!AnyHelper.isNullOrEmptyArray(asset.deductibles))
		{
			(<any>assetFieldConfig.fieldArray).fieldGroup.push(
				<FormlyFieldConfig>
				{
					key: 'deductibles',
					type: FormlyConstants
						.customControls
						.customRepeater,
					defaultValue: [],
					props: {
						label: 'Asset Deductibles',
						singular: 'Deductible',
						titleFormat: '{name}',
						disabled: true,
						filter: AppConstants.empty,
						initialItemValue: {}
					},
					fieldArray: {
						fieldGroup: [
							<FormlyFieldConfig>
							{
								key: 'name',
								type: FormlyConstants
									.customControls
									.input,
								wrappers: [
									FormlyConstants
										.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Deductible'
								}
							},
							<FormlyFieldConfig>
							{
								key: 'amount',
								type: FormlyConstants
									.customControls
									.input,
								wrappers: [
									FormlyConstants
										.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Amount'
								}
							}
						]
					},
					expressions: {
						hide: 'true'
					}
				},

				await this.createMarkdownField(
					'deductibles',
					'|Deductibles||\n',
					true));
		}
	}

	/**
	 * Adds the modifiers to the asset section for the dynamic Formly layout.
	 *
	 * @async
	 * @param {any} asset
	 * The asset object containing coverage information.
	 * @param {FormlyFieldConfig} assetFieldConfig
	 * The Formly field configuration for assets.
	 * @memberof PolicyDetailsComponent
	 */
	private async addAssetModifiers(
		asset: any,
		assetFieldConfig: FormlyFieldConfig)
	{
		if (!AnyHelper.isNullOrEmptyArray(asset.modifiers))
		{
			(<any>assetFieldConfig.fieldArray).fieldGroup.push(
				<FormlyFieldConfig>
				{
					key: 'modifiers',
					type: FormlyConstants
						.customControls
						.customRepeater,
					defaultValue: [],
					props: {
						label: 'Asset Modifiers',
						singular: 'Modifier',
						titleFormat: '{name}',
						disabled: true,
						filter: AppConstants.empty,
						initialItemValue: {}
					},
					fieldArray: {
						fieldGroup: [
							<FormlyFieldConfig>{
								key: 'name',
								type: FormlyConstants
									.customControls
									.input,
								wrappers: [
									FormlyConstants
										.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Modifier'
								}
							},
							<FormlyFieldConfig>
							{
								key: 'selection',
								type: FormlyConstants
									.customControls
									.input,
								wrappers: [
									FormlyConstants
										.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Selection'
								}
							}
						]
					},
					expressions: {
						hide: 'true'
					}
				},
				await this.createMarkdownField(
					'modifiers',
					'|Modifiers||\n',
					true));
		}
	}

	/**
	 * Creates a markdown field configuration for displaying a list of
	 * coverages, deductibles, or modifiers in the Formly layout.
	 *
	 * @async
	 * @param {string} key
	 * The key representing the type of items.
	 * @param {string} header
	 * The markdown header to use for the field content.
	 * @param {boolean} assetPath
	 * Optional boolean to identify if this is asset related data (defaulted to false - policy level).
	 * @returns {Promise<FormlyFieldConfig>}
	 * The Formly field configuration for the markdown display.
	 * @memberof PolicyDetailsComponent
	 */
	private async createMarkdownField(
		key: string,
		header: string,
		assetPath: boolean = false): Promise<FormlyFieldConfig>
	{
		return <FormlyFieldConfig>
		{
			type: FormlyConstants
				.customControls
				.customTextDisplay,
			props: {
				content: AppConstants.empty,
				useMarkdown: true,
				usePanelDisplay: false,
				centerText: false
			},
			expressions: {
				'props.content':
					(field: FormlyFieldConfig) =>
					{
						let markdown =
							this.uig12ClassDiv
							+ '\r\r'
							+ header
							+ (assetPath
								? this.assetTableSeparator
								: this.tableSeparator);

						const items = assetPath
							? field.model[key]
							: field.model.data[key];

						items.forEach(
							(item: any) =>
							{
								const itemName =
									item.name;
								const itemValue =
									key === 'modifiers'
										? StringHelper
											.formatBooleanValue(
												item.selection,
												true)
										: StringHelper
											.numberToCurrency(
												item.amount ?? 0);

								markdown += `|${itemName}|${itemValue}|\n`;
							});

						markdown += '\r\r</div>';

						return markdown;
					}
			}
		};
	}

	/**
	 * Handles the Interests layout.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @async
	 * @memberof PolicyDetailsComponent
	 */
	private async setInterestsLayout(): Promise<void>
	{
		this.dynamicFormlyLayoutInterests =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'data.interests',
					type: FormlyConstants
						.customControls.customRepeater,
					defaultValue: [],
					props: {
						label: 'Interests',
						singular: 'Interest',
						titleFormat: '{type} - {name.firstName}'
							+ ' {name.lastName}',
						disabled: true,
						filter: AppConstants.empty,
						initialItemValue: {}
					},
					fieldArray: {
						fieldGroup: [
							<FormlyFieldConfig>
							{
								key: 'type',
								type: FormlyConstants
									.customControls.customSelect,
								wrappers: [
									FormlyConstants
										.customControls.customFieldWrapper
								],
								props: {
									label: 'Type',
									options: [
										{
											label: 'Additional Insured',
											value: 'AdditionalInsured'
										},
										{
											label: 'Additional Interest',
											value: 'AdditionalInterest'
										},
										{
											label: 'Caregiver',
											value: 'Caregiver'
										},
										{
											label: 'Co-lessee',
											value: 'CoLessee'
										},
										{
											label: 'Co-owner',
											value: 'CoOwner'
										},
										{
											label: 'Co-signer',
											value: 'CoSigner'
										},
										{
											label: 'Condo Association',
											value: 'CondoAssociation'
										},
										{
											label: 'Estate',
											value: 'Estate'
										},
										{
											label: 'Executor',
											value: 'Executor'
										},
										{
											label: 'Friend',
											value: 'Friend'
										},
										{
											label: 'Irrevocable Trust',
											value: 'IrrevocableTrust'
										},
										{
											label: 'Landlord',
											value: 'Landlord'
										},
										{
											label: 'LLC',
											value: 'LLC'
										},
										{
											label: 'Management Company',
											value: 'ManagementCompany'
										},
										{
											label: 'Mortgagee',
											value: 'Mortgagee'
										},
										{
											label: 'Named Insured',
											value: 'NamedInsured'
										},
										{
											label: 'Other Resident '
												+ 'Of Household',
											value: 'OtherResidentOfHousehold'
										},
										{
											label: 'Power Of Attorney',
											value: 'PowerOfAttorney'
										},
										{
											label: 'Property Manager',
											value: 'PropertyManager'
										},
										{
											label: 'Relative',
											value: 'Relative'
										},
										{
											label: 'Revocable Trust',
											value: 'RevocableTrust'
										},
										{
											label: 'Roommate',
											value: 'Roommate'
										},
										{
											label: 'Signor',
											value: 'Signor'
										},
										{
											label: 'Third Party Designee',
											value: 'ThirdPartyDesignee'
										},
										{
											label: 'Trustee',
											value: 'Trustee'
										}
									]
								},
								expressions: {
									hide: 'true'
								}
							},
							<FormlyFieldConfig>
							{
								key: 'name.firstName',
								type: FormlyConstants
									.customControls.input,
								wrappers: [
									FormlyConstants
										.customControls.customFieldWrapper
								],
								props: {
									label: 'First Name'
								},
								expressions: {
									hide: 'true'
								}
							},
							<FormlyFieldConfig>
							{
								key: 'name.lastName',
								type: FormlyConstants
									.customControls.input,
								wrappers: [
									FormlyConstants
										.customControls.customFieldWrapper
								],
								props: {
									label: 'Last Name'
								},
								expressions: {
									hide: 'true'
								}
							},
							<FormlyFieldConfig>
							{
								key: 'phones[0].number',
								type: FormlyConstants
									.customControls.input,
								wrappers: [
									FormlyConstants
										.customControls.customFieldWrapper
								],
								props: {
									label: 'Phone'
								},
								expressions: {
									hide: 'true'
								}
							},
							<FormlyFieldConfig>
							{
								type: FormlyConstants
									.customControls.customTextDisplay,
								props: {
									content: AppConstants.empty,
									useMarkdown: true,
									usePanelDisplay: false,
									centerText: false
								},
								expressions: {
									'props.content':
										(field: FormlyFieldConfig) =>
										{
											const interestName: string =
												StringHelper.toNameString(
													field.model?.name?.firstName,
													field.model?.name?.lastName);

											const interestPhone: string =
												!AnyHelper.isNullOrEmpty(
													field.model?.phones)
													&& !AnyHelper.isNullOrEmpty(
														field.model?.phones[0]?.number)
													? field.model?.phones[0]?.number
													: AppConstants.empty;

											const markdown: string =
												this.uig12ClassDiv
													+ this.dataLabelsSummaryClassDiv
														+ this.ellipsisClassDiv
															+ 'Name'
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ 'Phone'
														+ this.divEnd
													+ this.divEnd
													+ this.dataSetSummaryClassDiv
														+ this.ellipsisClassDiv
															+ interestName
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ interestPhone
														+ this.divEnd
													+ this.divEnd
												+ this.divEnd;

											return markdown;
										}
								}
							}
						]
					}
				}
			];
	}

	/**
	 * Handles the Incidents layout.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @async
	 * @memberof PolicyDetailsComponent
	 */
	private async setIncidentsLayout(): Promise<void>
	{
		this.dynamicFormlyLayoutIncidents =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'data.incidents',
					type: FormlyConstants
						.customControls.customRepeater,
					defaultValue: [],
					props: {
						label: 'Incidents',
						singular: 'Incident',
						titleFormat: '{description}',
						disabled: true,
						filter: AppConstants.empty,
						initialItemValue: {}
					},
					fieldArray: {
						fieldGroup: [
							<FormlyFieldConfig>
							{
								key: 'description',
								type: FormlyConstants
									.customControls.input,
								wrappers: [
									FormlyConstants
										.customControls.customFieldWrapper
								],
								props: {
									label: 'Description'
								},
								expressions: {
									hide: 'true'
								}
							},
							<FormlyFieldConfig>
							{
								key: 'appliedTo',
								type: FormlyConstants
									.customControls.customRepeater,
								defaultValue: [],
								props: {
									label: 'Applied To',
									singular: 'Applied',
									titleFormat: '{scope}',
									disabled: true,
									filter: AppConstants.empty,
									initialItemValue: {}
								},
								fieldArray: {
									fieldGroup: [
										<FormlyFieldConfig>
										{
											key: 'scope',
											type: FormlyConstants
												.customControls.input,
											wrappers: [
												FormlyConstants
													.customControls
													.customFieldWrapper
											],
											props: {
												label: 'Description'
											},
											expressions: {
												hide: 'true'
											}
										}
									]
								},
								expressions: {
									hide: 'true'
								}
							},
							<FormlyFieldConfig>
							{
								type: FormlyConstants
									.customControls.customTextDisplay,
								props: {
									content: AppConstants.empty,
									useMarkdown: true,
									usePanelDisplay: false,
									centerText: false
								},
								expressions: {
									'props.content':
										(field: FormlyFieldConfig) =>
										{
											const occurrenceDate: string =
												!AnyHelper.isNullOrEmpty(
													field.model?.occurrenceDate)
													? DateHelper.formatDate(
														DateHelper.fromUtcIso(
															field.model.occurrenceDate),
														DateHelper.presetFormats
															.longDateFormat)
													: AppConstants.empty;

											const reportedDate: string =
												!AnyHelper.isNullOrEmpty(
													field.model?.reportedDate)
													? DateHelper.formatDate(
														DateHelper.fromUtcIso(
															field.model.reportedDate),
														DateHelper.presetFormats
															.longDateFormat)
													: AppConstants.empty;

											const lossAmount: string =
												field.model.lossAmount
													? StringHelper.numberToCurrency(
														field.model.lossAmount)
													: AppConstants.empty;

											const paidAmount: string =
												field.model.paidAmount
													? StringHelper.numberToCurrency(
														field.model.paidAmount)
													: AppConstants.empty;

											const markdown: string =
												this.uig12ClassDiv
													+ this.dataLabelsCoveragesClassDiv
														+ this.ellipsisClassDiv
															+ 'Type'
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ 'Source'
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ 'Status'
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ 'Occurrence Date'
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ 'Reported Date'
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ 'Loss Amount'
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ 'Paid Amount'
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ 'Description'
														+ this.divEnd
													+ this.divEnd
													+ this.dataSetCoveragesClassDiv
														+ this.ellipsisClassDiv
															+ (field.model.type
																?? AppConstants.empty)
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ (field.model.source
																?? AppConstants.empty)
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ (field.model.status
																?? AppConstants.empty)
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ occurrenceDate
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ reportedDate
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ lossAmount
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ paidAmount
														+ this.divEnd
														+ this.ellipsisClassDiv
															+ (field.model.description
																?? AppConstants.empty)
														+ this.divEnd
													+ this.divEnd
												+ this.divEnd;

											return markdown;
										}
								}
							},
							<FormlyFieldConfig>
							{
								type: FormlyConstants
									.customControls.customTextDisplay,
								props: {
									content: AppConstants.empty,
									useMarkdown: true,
									usePanelDisplay: false,
									centerText: false
								},
								expressions: {
									'props.content':
										(field: FormlyFieldConfig) =>
										{
											let markdown =
												this.uig12ClassDiv
													+ '\r\r'
													+ '|Applied To|\n'
													+ '|------|\n';

											if (!AnyHelper.isNullOrEmptyArray(
												field.model.appliedTo))
											{
												field.model.appliedTo.forEach(
													(applied: any) =>
													{
														markdown +=
															'|'
															+ applied.scope
															+ '|\n';
													});
											}

											markdown += '\r\r</div>';

											return markdown;
										}
								}
							}
						]
					}
				}
			];
	}
}