<div class="ui-g-12 no-padding">
	<div class="ui-g-12 no-padding wizard-title">
		{{context.data.label}}
	</div>

	<div class="ui-g-12 no-padding wizard-steps-container"
		[ngClass]="{
			'left-right-mask': (displayLeftMask === true && displayRightMask === true),
			'left-mask': (displayLeftMask === true && displayRightMask === false),
			'right-mask': (displayLeftMask === false && displayRightMask === true)
		}">
		<a class="wizard-viewport-button left-button"
			(click)="scrollLeft()"
			[@booleanFadeAnimation]="displayLeftMask === true">
			<span class="wizard-viewport-button-icon fa fa-fw fa-arrow-circle-left">
			</span>
		</a>
		<div #WizardViewport
			class="ui-g-12 no-padding wizard-viewport-container no-scrollbar"
			[ngClass]="{
				'draggable': (displayLeftMask === true || displayRightMask === true)
			}"
			(swipeleft)="swipe($event)"
			(swiperight)="swipe($event)"
			(press)="press()"
			(pressup)="pressUp()"
			(mouseup)="pressUp()">
			<p-steps
				[model]="context.data.displayedWizardMenuItems"
				[activeIndex]="activeStepIndex"
				[readonly]="false">
			</p-steps>
		</div>
		<a class="wizard-viewport-button right-button"
			[ngClass]="{
				'left-right-mask': (displayLeftMask === true && displayRightMask === true),
				'right-mask': (displayLeftMask === false && displayRightMask === true)
			}"
			(click)="scrollRight()"
			[@booleanFadeAnimation]="displayRightMask === true">
			<span class="wizard-viewport-button-icon fa fa-fw fa-arrow-circle-right">
			</span>
		</a>
	</div>

	<div class="ui-g-12 no-horizontal-padding"
		*ngIf="activeWizardStep != null">
		<ng-template
			[ngIf]="activeWizardStep.stepLayout != null && activeWizardStep.displayComponent == null"
			[ngIfElse]="DisplayComponent">
			<div class="ui-g-12 no-horizontal-padding wizard-content-title">
				{{activeWizardStep.stepDefinition.title}}
			</div>

			<div class="ui-g-12 no-padding dynamic-form-content"
				[ngClass]="{
					'centered-content': (activeWizardStep.stepLayout.length === 1 && activeWizardStep.stepLayout[0].props.displayCenter === true)
				}">

				<div *ngIf="redraw === false">
					<ng-template [ngIf]="allowedToContinue === false"
						[ngIfElse]="Content">
						<div class="ui-g-12 no-horizontal-padding text-center">
							<span class="panel-sub-title">
								{{activeWizardStep.clientMessage}}
							</span>
						</div>
					</ng-template>
					<ng-template #Content>
						<app-dynamic-formly
							[dataSet]="activeMenuItem.currentData"
							[initialData]="activeMenuItem.lastContactData"
							[layoutSchema]="activeWizardStep.stepLayout"
							[context]="context"
							(validityChanged)="validStepChanged($event)"
							(valuesChanged)="stepDataChanged($event)">
						</app-dynamic-formly>
					</ng-template>
				</div>
			</div>
		</ng-template>

		<ng-template #DisplayComponent>
			<div class="ui-g-12 no-padding dynamic-component-content">
				<app-dynamic-component
					[loading]="false"
					[context]="context"
					[displayComponent]="activeWizardStep.displayComponent">
				</app-dynamic-component>
			</div>
		</ng-template>

		<div class="ui-g-12 no-padding text-center button-set">
			<a id="stepBackAction"
				class="text-link theme-color secondary-action-button"
				*ngIf="activeWizardStep.backLabel != null"
				(click)="back()"
				[attr.disabled]="wizardStepLoading === true">
				{{activeWizardStep.backLabel}}
			</a>
			<span
				[pTooltip]="activeWizardStep.nextTooltipLabel"
				tooltipPosition="right"
				tooltipStyleClass="button-bar-tooltip">
				<button
					pButton
					type="button"
					[label]="activeWizardStep.nextLabel"
					*ngIf="activeWizardStep.nextLabel != null"
					(click)="next()"
					[disabled]="isValid === false || wizardStepLoading === true">
				</button>
			</span>
		</div>
	</div>
</div>