
<ng-template [ngIf]="!loading"
	[ngIfElse]="LoadingData">
	<div class="list-item-container ui-g ui-g-12 no-padding">
		<ng-template [ngIf]="coveragesAndDeductibles.policy != null || coveragesAndDeductibles.nonPolicy != null || (coveragesAndDeductibles.nonPolicy?.length || 0) > 0"
			[ngIfElse]="NoData">
			<div #Policy
				class="policy-wrapper"
				*ngIf="coveragesAndDeductibles.policy != null">

				<p-divider align="left">
					<div>
						<span class="panel-sub-title">
							Policy
						</span>
					</div>
				</p-divider>

				<div class="category-section"
					*ngIf="(coveragesAndDeductibles.policy.coverages?.length || 0) > 0">
					<strong>Coverages</strong>
					<ul class="square-bullets">
						<ng-template
							ngFor
							let-policyCoverage
							[ngForOf]="coveragesAndDeductibles.policy.coverages">
							<li>
								{{policyCoverage.name}}: {{getCurrencyAmount(policyCoverage.amount)}}
							</li>
						</ng-template>
					</ul>
				</div>

				<div class="category-section"
					*ngIf="(coveragesAndDeductibles.policy.deductibles?.length || 0) > 0">
					<strong>Deductibles</strong>
					<ul class="square-bullets">
						<ng-template
							ngFor
							let-policyDeductible
							[ngForOf]="coveragesAndDeductibles.policy.deductibles">
							<li>
								{{policyDeductible.name}}: {{getCurrencyAmount(policyDeductible.amount)}}
							</li>
						</ng-template>
					</ul>
				</div>
			</div>

			<div #NonPolicy
				class="non-policy-wrapper"
				*ngIf="(coveragesAndDeductibles.nonPolicy?.length || 0) > 0">

				<ng-template
					ngFor
					let-nonPolicyItem
					[ngForOf]="coveragesAndDeductibles.nonPolicy">

					<p-divider align="left">
						<div>
							<span class="panel-sub-title">
								{{getScopeTitle(nonPolicyItem[0])}}
							</span>
						</div>
					</p-divider>

					<ng-template
						ngFor
						let-scopeItem
						[ngForOf]="nonPolicyItem[1]">

						<div class="type-wrapper">

							<span class="type-section panel-sub-title">
								{{scopeItem.type}}
							</span>
							<span class='font-italic'>
								{{scopeItem.description}}
							</span>

							<div class="category-section"
								*ngIf="(scopeItem.coverages?.length || 0) > 0">
								<strong>Coverages</strong>
								<ul class="square-bullets">
									<ng-template
										ngFor
										let-nonPolicyCoverage
										[ngForOf]="scopeItem.coverages">
										<li>
											{{nonPolicyCoverage.name}}: {{getCurrencyAmount(nonPolicyCoverage.amount)}}
										</li>
									</ng-template>
								</ul>
							</div>

							<div class="category-section"
								*ngIf="(scopeItem.deductibles?.length || 0) > 0">
								<strong>Deductibles</strong>
								<ul class="square-bullets">
									<ng-template
										ngFor
										let-nonPolicyDeductible
										[ngForOf]="scopeItem.deductibles">
										<li>
											{{nonPolicyDeductible.name}}: {{getCurrencyAmount(nonPolicyDeductible.amount)}}
										</li>
									</ng-template>
								</ul>
							</div>
						</div>
					</ng-template>
				</ng-template>
			</div>
		</ng-template>

		<ng-template #NoData>
			<div class="no-data text-center ui-g-12">
				<span class="panel-sub-title">
					No Data
				</span>
			</div>
		</ng-template>
	</div>
</ng-template>

<ng-template #LoadingData>
	<div class="text-center ui-g-12">
		<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
		</span>
	</div>
</ng-template>