<div class="ui-g-12 no-padding">
	<ng-template [ngIf]="context.source.wizardStepLoading" [ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner"></i>
		</div>
	</ng-template>

	<ng-template #Content>
		<div class="ui-g-12 no-padding card-container">
			<ng-template #NoResults
				[ngIf]="summaryCardItems?.length === 0"
				[ngIfElse]="NestedContent">
				<div class="ui-g-12 text-center">
					No results found.
				</div>
			</ng-template>
			<ng-template #NestedContent>
				<div class="ui-g-12 no-padding cursor-pointer summary-card-item"
					*ngFor="let item of summaryCardItems">
					<app-summary-card
						[summaryTemplate]="item.summaryTemplate"
						[titleTemplate]="item.titleTemplate"
						[summaryCardDisplay]="item.summaryCardDisplay"
						[squareCardDisplay]="true"
						[dataPromise]="item.dataPromise"
						(summaryCardClick)="summaryCardClicked($event)">
					</app-summary-card>
				</div>
			</ng-template>
		</div>
	</ng-template>
</div>
