/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	IEntityInstanceDto
} from '@api/interfaces/entities/entity-instance.dto.interface';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';

/* eslint-enable max-len */

@Component({
	selector: 'select-claim-type',
	templateUrl: './select-claim-type.component.html',
	styleUrls: ['./select-claim-type.component.scss']
})

/**
 * A component representing a wizard step for selecting claim type.
 *
 * @export
 * @class SelectClaimTypeComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class SelectClaimTypeComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the select claim type component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @memberof SelectClaimTypeComponent
	 */
	public constructor(
		public router: Router,
		public entityInstanceApiService: EntityInstanceApiService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof SelectClaimTypeComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the summary card items.
     * This property dynamically updates to include all claim products defined.
	 *
	 * @type {any[]}
	 * @memberof SelectClaimTypeComponent
	 */
	public summaryCardItems: any[];

	/**
	 * A mapping of claim product types to their
	 * corresponding Font Awesome icon classes.
	 *
	 * @type {{[key: string]: string}}
	 * @memberof SelectClaimTypeComponent
	 */
	private readonly productIconMap: {[key: string]: string} =
		{
			'auto':
				`${AppConstants.cssClasses.fontAwesomeCar}`,
			'dwelling':
				`${AppConstants.cssClasses.fontAwesomeHouse}`
					+ ` ${AppConstants.cssClasses.largeFont}`,
			'renters':
				`${AppConstants.cssClasses.fontAwesomeHouse}`
					+ ` ${AppConstants.cssClasses.largeFont}`,
			'homeowners':
				`${AppConstants.cssClasses.fontAwesomeHouse}`
					+ ` ${AppConstants.cssClasses.largeFont}`,
		};

	/**
	 * Handles the summary card data on initialization.
	 * This will setup the required variables
	 * for the select claim type component.
	 *
	 * @async
	 * @memberof SelectClaimTypeComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.setupSummaryCardItems();
		this.context.source.wizardStepLoading = false;
		this.context.source
			.changeDetectorReference
			.detectChanges();
	}

	/**
	 * Configures the summary card items for this component.
	 * Retrieves claim products and sets up summary card data for each product.
	 *
	 * @async
	 * @memberof SelectClaimTypeComponent
	 */
	public async setupSummaryCardItems(): Promise<void>
	{
		const claimProducts: IEntityInstance[] =
			await this.getClaimProducts();
		this.summaryCardItems =
			claimProducts.map(
				(product: any) =>
				{
					const productName: string =
						product.data.name;
					const iconClass: string =
						this.productIconMap[productName.toLowerCase()]
							|| 'fa-question';

					return {
						dataPromise:
							Promise.resolve(
								{
									name: productName
								}),
						summaryTemplate:
							`<i class="${iconClass} theme-color"></i>`,
						titleTemplate: '${data.name}',
						summaryCardDisplay: true
					};
				});
	}

	/**
	 * Queries and retrieves a list of claim products.
	 *
	 * @async
	 * @returns {Promise<IEntityInstanceDto[]>}
	 * A promise that resolves to an array of claim products.
	 * @memberof SelectClaimTypeComponent
	 */
	public async getClaimProducts(): Promise<IEntityInstanceDto[]>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claimProducts;

		return this.entityInstanceApiService.query(
			AppConstants.empty,
			AppConstants.empty);
	}

	/**
	* Handles the click event for a claim product summary card.
	*
	* @remarks
	* This method extracts the product name from
	* the clicked summary card element,
	* updates the step data with this product name,
	* and then navigates to the "Find Policy" step.
	*
	* @param {Object} data
	* The data associated with the clicked summary card.
	* @memberof SelectClaimTypeComponent
	*/
	public summaryCardClicked(
		data: {
			dynamicComponent: string;
			summaryCardElement: HTMLDivElement;
			dynamicComponentContext: any;
		})
	{
		this.context.source.addOrUpdateStepData(
			{
				claim:
					{
						productName: data.summaryCardElement.innerText
					}
			});
		this.context.source.stepTo('Find Policy');
	}
}