/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Injectable
} from '@angular/core';
import {
	IEntityInstanceDto
} from '@api/interfaces/entities/entity-instance.dto.interface';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	User
} from '@shared/implementations/users/user';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	UserService
} from '@shared/services/user.service';
import {
	isArray
} from 'lodash';
import {
	DateTime
} from 'luxon';
/* eslint-enable max-len */

/**
 * A class representing a common interface to gather fully populated
 * claims information.
 *
 * @export
 * @class ClaimsService
 */
@Injectable()
export class ClaimsService
{
	/**
	 * Creates an instance of an ClaimsService.
	 *
	 * @param {EntityService} entityService
	 * The entity service for this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service for this component.
	 * @param {InsuranceService} insuranceService
	 * The insurance service for this component.
	 * @param {ResolverService} resolverService
	 * The resolver service for this component.
	 * @param {SecurityGroupApiService} securityGroupApiService
	 * The security group api service for this component.
	 * @param {UserService} userService
	 * The user service for this component.
	 * @memberof ClaimsService
	 */
	public constructor(
		public readonly entityService: EntityService,
		public readonly entityInstanceApiService: EntityInstanceApiService,
		public readonly insuranceService: InsuranceService,
		public readonly resolverService: ResolverService,
		public readonly securityGroupApiService: SecurityGroupApiService,
		public readonly userService: UserService)
	{
	}

	/**
	 * Gets the id descending query used for the order by
	 * in common entity queries.
	 *
	 * @type {string}
	 * @memberof ClaimsService
	 */
	private readonly idDescendingQuery: string = 'Id desc';

	/**
	 * Gets the Ledger instance data associated to the current claim
	 * instance.
	 *
	 * @async
	 * @param {number} claimId
	 * The Claim entity instance id.
	 * @return {Promise<IEntityInstance>}
	 * The Ledger instance.
	 * @memberof ClaimsService
	 */
	public async getLedger(
		claimId: number): Promise<IEntityInstance>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				ClaimConstants.claimEntityTypeGroups.claims;

		const ledgerInstances: IEntityInstance[] =
			await this.entityInstanceApiService
				.getChildren(
					claimId,
					null,
					null,
					null,
					null,
					ClaimConstants.ledgerEntityTypeGroups
						.ledgers);

		return ledgerInstances[0];
	}

	/**
	 * Gets the LedgerTransacion.Claim entity instances associated to
	 * the current Ledger instance.
	 *
	 * @async
	 * @param {number} ledgerId
	 * The Ledger entity instance id.
	 * @return {Promise<IEntityInstance[]>}
	 * The LedgerTransaction instances array.
	 * @memberof ClaimsService
	 */
	public async getLedgerTransactions(
		ledgerId: number,
		filter: string = null): Promise<IEntityInstance[]>
	{
		const ledgerTransactionInstances: IEntityInstance[] =
			await this.entityService
				.getFullHierarchyDataSet(
					ledgerId,
					ClaimConstants.ledgerEntityTypeGroups.ledgers,
					filter,
					AppConstants.empty,
					true,
					ClaimConstants.ledgerEntityTypeGroups
						.ledgerTransactionClaims);

		return ledgerTransactionInstances;
	}

	/**
	 * Given a set of ledger transactions, this will sum the amounts
	 * and return the full calculated amount of the sent data set.
	 *
	 * @param {any[]} ledgerTransactions
	 * The ledger transactions.
	 * @returns {number}
	 * The summed amounts of all of the transactions sent.
	 * @memberof ClaimsService
	 */
	public sumLedgerTransactions(
		ledgerTransactions: any[]): number
	{
		return ledgerTransactions
			.reduce(
				(sum: number,
					item: any) =>
					sum
						+ item.data.amount,
				0);
	}

	/**
	 * Given a claims organization type, legal name, and a user's
	 * first and last name, the data will be formatted for summary
	 * display of data. Output is
	 * "type: legalName, userFirstName userLastName"
	 *
	 * @param {string | []} organizationType
	 * The organization type.
	 * @param {string} organizationLegalName
	 * The organization legal name.
	 * @param {string} userFirstName
	 * The user first name.
	 * @param {string} userLastName
	 * The user last name.
	 * @returns {string}
	 * The formatted summary data of the organization.
	 * @memberof ClaimsService
	 */
	public formatClaimOrganizationData(
		organizationType: string | string[],
		organizationLegalName: string,
		userFirstName: string,
		userLastName: string): string
	{
		const organizationTypeExists: boolean =
			isArray(organizationType)
				? !AnyHelper.isNullOrEmptyArray(organizationType)
				: !AnyHelper.isNullOrWhitespace(organizationType);

		const formattedName =
			StringHelper.toNameString(
				userFirstName,
				userLastName,
				organizationLegalName);

		const formattedOrganizationType: string | string[] =
				organizationTypeExists
					? this.formatOrganizationType(organizationType)
					: null;

		return organizationTypeExists
			? `${formattedOrganizationType}: ${formattedName}`
			: formattedName;
	}

	/**
	 * Given an organization type, the data will be formatted
	 * for summary display with correct character casing.
	 *
	 * @param {string | []} organizationType
	 * The organization type.
	 * @returns {string | []}
	 * The formatted organization type.
	 * @memberof ClaimsService
	 */
	public formatOrganizationType(
		organizationType: string | string[]): string | string[]
	{
		const multipleTypes: boolean =
			isArray(organizationType)
				? true
				: false;

		if (multipleTypes)
		{
			const formattedTypes: string[] = [];
			for (const type of organizationType)
			{
				formattedTypes.push(
					StringHelper.beforeCapitalSpaces(type));
			}

			return formattedTypes;
		}

		return StringHelper.beforeCapitalSpaces(organizationType as string);
	}

	/**
	 * Given an ledger transaction type this method will return the friendly
	 * name to be displayed.
	 *
	 * @param {string} transactionType
	 * The ledger transaction type.
	 * @returns {string}
	 * The friendly name for the ledger transaction type.
	 * @memberof ClaimsService
	 */
	public getFriendlyTransactionTypeName(
		transactionType: string): string
	{
		const transactionTypes: {[key: string]: string} =
			{
				AdjustingAndOther: 'AO',
				DefenseAndCostContainment: 'DCC',
				Loss: 'Loss'
			};

		return transactionTypes[transactionType];
	}

	/**
	 * Given an exposure id and transaction type this method will
	 * return all of the applicable ledger transactions.
	 *
	 * @async
	 * @param {number} claimPaymentId
	 * The claim payment id.
	 * @param {string} exposureId
	 * The exposure id.
	 * @param {string} transactionType
	 * The ledger transaction claim type.
	 * @returns {any[]}
	 * The applicable ledger transactions.
	 * @memberof ClaimsService
	 */
	public async getClaimPaymentExposureLedgerTransactions(
		claimPaymentId: number,
		exposureId: string,
		transactionType: string): Promise<any[]>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claimPayments;

		const claims: IEntityInstanceDto[] =
			await this.entityInstanceApiService.getParents(
				claimPaymentId,
				AppConstants.empty,
				this.idDescendingQuery,
				null,
				null,
				ClaimConstants.claimEntityTypeGroups.claims);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		const ledgers: IEntityInstanceDto[] =
			await this.entityInstanceApiService.getChildren(
				claims[0].id,
				AppConstants.empty,
				this.idDescendingQuery,
				null,
				null,
				ClaimConstants.ledgerEntityTypeGroups.ledgers);

		const ledgerTransactions: IEntityInstanceDto[] =
			await this.entityService.getFullHierarchyDataSet(
				ledgers[0].id,
				ClaimConstants.ledgerEntityTypeGroups.ledgers,
				AppConstants.empty,
				this.idDescendingQuery,
				true,
				ClaimConstants.ledgerEntityTypeGroups.ledgerTransactionClaims);

		const filteredTransactions: any[] =
				ledgerTransactions.filter(
					(transaction: any) =>
						transaction.data?.metadata?.exposureId === exposureId
							&& transaction.data?.type === transactionType);

		return filteredTransactions;
	}

	/**
	 * Gets the claim product instance by name.
	 *
	 * @async
	 * @param {string} claimProductName
	 * The name of the claim product to get.
	 * @returns {Promise<IEntityInstance>}
	 * A promise that resolves to the
	 * claim product instance matching the specified name.
	 * @memberof ClaimsService
	 */
	public async getClaimProductByName(
		claimProductName: string): Promise<IEntityInstance>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claimProducts;

		return this.entityInstanceApiService
			.getSingleQueryResult(
				`${AppConstants.commonProperties.name} `
					+ `eq '${claimProductName}'`,
				AppConstants.empty);
	}

	/**
	 * Gets all active reasons for the given claim.
	 *
	 * @async
	 * @param {number} claimId
	 * The ID of the claim to get reasons for.
	 * @returns {Promise<any[]>}
	 * A promise that resolves to an array of claim reasons.
	 * @memberof ClaimsService
	 */
	public async getReasons(
		claimId: number): Promise<any[]>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		const claim: IEntityInstanceDto =
			await this.entityInstanceApiService.get(claimId);

		const claimProduct: IEntityInstanceDto =
			await this.getClaimProductByName(
				claim.data.productName);

		return claimProduct.data.reasons.filter(
			(reason: any) =>
				reason.active === true);
	}

	/**
	 * Gets claim reasons for the given claim.
	 *
	 * @async
	 * @param {number} claimId
	 * The ID of the claim to get reasons for.
	 * @param {string} type
	 * the type (open or closed) of the reason to get.
	 * @returns {Promise<any[]>}
	 * A promise that resolves to an array of claim reasons.
	 * @memberof ClaimsService
	 */
	public async getClaimReasons(
		claimId: number,
		type: string): Promise<any[]>
	{
		const reasons: any[] =
			await this.getReasons(claimId);

		return reasons.filter(
			(reason: any) =>
				reason.isClaimReason
					&& ((type === ClaimConstants.reasonType.open
						&& reason.isOpenReason)
							|| (type === ClaimConstants.reasonType.close
								&& reason.isCloseReason)));
	}

	/**
	 * Gets exposure reasons for the given claim.
	 *
	 * @async
	 * @param {number} claimId
	 * The ID of the claim to get reasons for.
	 * @param {string} type
	 * the type (open or closed) of the reason to get.
	 * @returns {Promise<any[]>}
	 * A promise that resolves to an array of expsosure reasons.
	 * @memberof ClaimsService
	 */
	public async getExposureReasons(
		claimId: number,
		type: string): Promise<any[]>
	{
		const reasons: any[] =
			await this.getReasons(claimId);

		return reasons.filter(
			(reason: any) =>
				reason.isExposureReason
					&& ((type === ClaimConstants.reasonType.open
						&& reason.isOpenReason)
							|| (type === ClaimConstants.reasonType.close
								&& reason.isCloseReason)));
	}

	/**
	 * Validates the exposure adustment amount.
	 *
	 * @async
	 * @param {number} claimId
	 * The claim id.
	 * @param {string} exposureId
	 * The exposure id.
	 * @param {string} expenseType
	 * The expense type.
	 * @param {number} adjustmentAmount
	 * The exposure adjustment amount.
	 * @returns {boolean}
	 * True if amount is valid otherewise false.
	 * @memberof ClaimsService
	 */
	public async validExposureAdjustmentAmount(
		claimId: number,
		exposureId: string,
		expenseType: string,
		adjustmentAmount: number): Promise<boolean>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		const claim: IEntityInstanceDto =
			await this.entityInstanceApiService.get(claimId);

		const ledger: IEntityInstance =
			await this.getLedger(claim.id);

		const ledgerTransactions: IEntityInstanceDto[] =
			await this.getLedgerTransactions(
				ledger.id,
				`metadata.exposureId eq \"${exposureId}\" `
					+ `and type eq \"${expenseType}\"`);

		const sum: number =
			parseFloat(
				this.sumLedgerTransactions(
					ledgerTransactions)
					.toFixed(2));

		return sum + adjustmentAmount >= 0;
	}

	/**
	 * Gets the applicable organizations for claim owners.
	 *
	 * @async
	 * @param {number} claimId
	 * The claim id.
	 * @returns {Promise<any[]>}
	 * The applicable organizations for claim owners.
	 * @memberof ClaimsService
	 */
	public async getClaimOwnerOrganizations(
		claimId: number): Promise<any[]>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		const insuranceCompanies: IEntityInstanceDto[] =
			await this.entityInstanceApiService.getParents(
				claimId,
				AppConstants.empty,
				AppConstants.empty,
				null,
				AppConstants.dataLimits.large,
				ClaimConstants.serviceProviderEntityTypeGroups
					.insuranceCompany);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		const thirdPartyAdministrators: IEntityInstanceDto[] =
			await this.entityInstanceApiService.getParents(
				claimId,
				AppConstants.empty,
				AppConstants.empty,
				null,
				AppConstants.dataLimits.large,
				ClaimConstants.serviceProviderEntityTypeGroups
					.thirdPartyAdministrator);

		return insuranceCompanies.concat(thirdPartyAdministrators);
	}

	/**
	 * Gets the valid named events for a specified claim product,
	 * based on the provided loss and reported dates.
	 *
	 * @async
	 * @param {string} claimProductName
	 * The name of the claim product for which to get named events.
	 * @param {string} lossDate
	 * The date of loss to check against named events (expected ISO).
	 * @param {string} reportedDate
	 * The reported date to check against closure dates (expected ISO).
	 * @returns {Promise<any[]>}
	 * A promise that resolves to an array of valid named events.
	 * @memberof ClaimsService
	 */
	public async getValidNamedEvents(
		claimProductName: string,
		lossDate: string,
		reportedDate: string): Promise<any[]>
	{
		const validEvents: any[] = [];
		const dateTimeLossDate: DateTime =
			DateTime.fromISO(lossDate);
		const dateTimeReportedDate: DateTime =
			DateTime.fromISO(reportedDate);

		const claimProduct: IEntityInstanceDto =
			await this.getClaimProductByName(claimProductName);

		const namedEvents: any[] =
			claimProduct.data.namedEvents;

		namedEvents.forEach(
			(namedEvent: any) =>
			{
				const startDate: DateTime =
					DateTime.fromISO(namedEvent.startDate);
				const endDate: DateTime =
					DateTime.fromISO(namedEvent.endDate);
				const closureDate: DateTime | null =
					namedEvent.closureDate
						? DateTime.fromISO(namedEvent.closureDate)
						: null;

				const isLossWithinEvent: boolean =
					(dateTimeLossDate >= startDate)
						&& (dateTimeLossDate <= endDate);

				const isReportedBeforeClosure: boolean =
					closureDate === null ||
						(dateTimeReportedDate < closureDate);

				if (isLossWithinEvent && isReportedBeforeClosure)
				{
					validEvents.push(namedEvent);
				}
			});

		return validEvents;
	}

	/**
	 * Retrieves the name of a named event for
	 * a given claim product and named event Id.
	 *
	 * @async
	 * @param {number} namedEventId
	 * The Id of the named event to find.
	 * @param {string} claimProductName
	 * The name of the claim product for which to retrieve loss causes.
	 * @returns {Promise<string>}
	 * A promise that resolves to the name of the matching named event,
	 * or `undefined` if no match is found.
	 * @memberof ClaimsService
	 */
	public async getNamedEventName(
		namedEventId: number,
		claimProductName: string): Promise<string>
	{
		const claimProduct: IEntityInstanceDto =
			await this.getClaimProductByName(claimProductName);

		const namedEvents: any[] =
			claimProduct.data.namedEvents;

		return namedEvents.find(
			(namedEvent: any) =>
				namedEvent.id === namedEventId.toString())?.name;
	}

	/**
	 * Gets all enabled loss causes for a given claim product.
	 *
	 * @async
	 * @param {string} claimProductName
	 * The name of the claim product for which to get loss causes.
	 * @returns {Promise<any[]>}
	 * A promise that resolves to an array of loss causes.
	 * Each item in the array represents a named event associated
	 * with the specified claim product.
	 * @memberof ClaimsService
	 */
	public async getLossCauses(
		claimProductName: string): Promise<any[]>
	{
		const claimProduct: IEntityInstanceDto =
			await this.getClaimProductByName(claimProductName);

		const lossCauses: any[] =
			claimProduct.data.lossCauses;

		return lossCauses.filter(
			(cause: any) =>
				cause.enabled === true);
	}

	/**
	 * Retrieves the name of a loss cause for
	 * a given claim product and loss cause Id.
	 *
	 * @async
	 * @param {number} lossCauseId
	 * The Id of the loss cause to find.
	 * @param {string} claimProductName
	 * The name of the claim product for which to retrieve loss causes.
	 * @returns {Promise<string>}
	 * A promise that resolves to the name of the matching loss cause,
	 * or `undefined` if no match is found.
	 * @memberof ClaimsService
	 */
	public async getLossCauseName(
		lossCauseId: number,
		claimProductName: string): Promise<string>
	{
		const lossCauses: any[] =
			await this.getLossCauses(claimProductName);

		return lossCauses.find(
			(lossCause: any) =>
				lossCause.id === lossCauseId.toString())?.name;
	}

	/**
	 * Gets the claim owner users.
	 *
	 * @async
	 * @param {number} organizationId
	 * The organization id.
	 * @param {string} organizationTypeGroup
	 * The organization id.
	 * @returns {any[]}
	 * The claim owner users under an organization.
	 * @memberof ClaimsService
	 */
	public async getClaimOwnerUsers(
		organizationId: number,
		organizationTypeGroup: string): Promise<any[]>
	{
		const claimOwnerUsers: any[] =
			await this.entityService.getFullHierarchyDataSet(
				organizationId,
				organizationTypeGroup,
				AppConstants.empty,
				AppConstants.empty,
				true,
				'Users');

		for await (const user of claimOwnerUsers)
		{
			await this.userService.setUserSecurityGroups(<User>user);
		}

		return claimOwnerUsers.filter(
			(user: any) =>
				user.membershipSecurityGroups.filter(
					(group: any) =>
						group.name.endsWith('_ClaimsManager'))
					.length > 0);
	}

	/**
	 * Gets Claims Vendor organizations.
	 *
	 * @async
	 * @param {number} claimId
	 * The claim instance id.
	 * @param {string} vendorType
	 * The vendor type.
	 * @returns {IEntityInstance[]}
	 * The applicable claims vendor organizations.
	 * @memberof ClaimsService
	 */
	public async getClaimVendorOrganizations(
		claimId: number,
		vendorType: string): Promise<IEntityInstance[]>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		const insuranceCompany: IEntityInstance[] =
			await this.entityInstanceApiService.getParents(
				claimId,
				AppConstants.empty,
				AppConstants.empty,
				0,
				1,
				ClaimConstants.serviceProviderEntityTypeGroups
					.insuranceCompany);

		const vendorCompanies: IEntityInstance[] =
			await this.entityService.getFullHierarchyDataSet(
				insuranceCompany[0].id,
				ClaimConstants.serviceProviderEntityTypeGroups.insuranceCompany,
				`vendorType eq \"${vendorType}\"`,
				AppConstants.empty,
				true,
				ClaimConstants.serviceProviderEntityTypeGroups
					.vendorCompany);

		 return vendorCompanies;
	}

	/**
	 * Gets Claims Adjuster organizations.
	 *
	 * @async
	 * @param {number} claimId
	 * The claim instance id.
	 * The vendor type.
	 * @returns {IEntityInstance[]}
	 * The applicable claims adjuster organizations.
	 * @memberof ClaimsService
	 */
	public async getClaimAdjusterOrganizations(
		claimId: number): Promise<IEntityInstance[]>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		const insuranceCompany: IEntityInstance[] =
			await this.entityInstanceApiService.getParents(
				claimId,
				AppConstants.empty,
				AppConstants.empty,
				0,
				1,
				ClaimConstants.serviceProviderEntityTypeGroups
					.insuranceCompany);

		const adjustingCompanies: IEntityInstance[] =
			await this.entityService.getFullHierarchyDataSet(
				insuranceCompany[0].id,
				ClaimConstants.serviceProviderEntityTypeGroups.insuranceCompany,
				AppConstants.empty,
				AppConstants.empty,
				true,
				ClaimConstants.serviceProviderEntityTypeGroups
					.adjustingCompany);

		const insuranceCompanies: IEntityInstance[] =
			await this.entityService.getFullHierarchyDataSet(
				insuranceCompany[0].id,
				ClaimConstants.serviceProviderEntityTypeGroups.insuranceCompany,
				AppConstants.empty,
				AppConstants.empty,
				true,
				ClaimConstants.serviceProviderEntityTypeGroups
					.insuranceCompany);

		const thirdPartyAdministratorCompanies: IEntityInstance[] =
			await this.entityService.getFullHierarchyDataSet(
				insuranceCompany[0].id,
				ClaimConstants.serviceProviderEntityTypeGroups.insuranceCompany,
				AppConstants.empty,
				AppConstants.empty,
				true,
				ClaimConstants.serviceProviderEntityTypeGroups
					.thirdPartyAdministrator);

		 return [...adjustingCompanies,
			...insuranceCompanies,
			...thirdPartyAdministratorCompanies];
	}

	/**
	 * Gets the key activity reference based on the activity name.
	 *
	 * @async
	 * @param {string} activityName
	 * The key activity name.
	 * @param {string} metadataIdentifier
	 * The metadata identifier associated to this key activity type.
	 * @param {IDynamicComponentContext<any, Component>} context
	 * The primary page component context holding a claim instance.
	 * @param {boolean} includeActivityName
	 * If true, this will include the activity name for the reference
	 * returned.
	 * @param {boolean} detailedDescription
	 * If true, this will output a detailed description.
	 * @returns {Promise<string>}
	 * An awaitable key activity reference ready for display.
	 * @memberof ClaimsService
	 */
	public async getKeyActivityReference(
		activityName: string,
		metadataIdentifier: string,
		context: IDynamicComponentContext<any, Component>,
		includeActivityName: boolean,
		detailedDescription: boolean): Promise<string>
	{
		const activityDescription: string =
			includeActivityName === true
				? activityName
					+ AppConstants.characters.colon
					+ AppConstants.characters.space
				: AppConstants.empty;

		if (activityName !== ClaimConstants.keyActivities.payment
			&& !StringHelper.isGuid(metadataIdentifier)
			&& isNaN(parseInt(
				metadataIdentifier,
				AppConstants.parseRadix)))
		{
			return activityDescription
				+ metadataIdentifier;
		}

		const entityInstanceComponent: EntityInstanceComponent =
			<EntityInstanceComponent>context.source;

		switch (activityName)
		{
			case ClaimConstants.keyActivities.claimOwnerAssigned:
			{
				return this.getClaimOwnerKeyActivityDescription(
					parseInt(
						metadataIdentifier,
						AppConstants.parseRadix),
					activityDescription,
					detailedDescription);
			}
			case ClaimConstants.keyActivities.exposureAdded:
			case ClaimConstants.keyActivities.exposureClosed:
			case ClaimConstants.keyActivities.exposureReopened:
			{
				return this.getExposureKeyActivityDescription(
					activityName,
					metadataIdentifier,
					entityInstanceComponent.entityInstance.data.exposures,
					activityDescription,
					detailedDescription);
			}
			case ClaimConstants.keyActivities.payment:
			{
				return this.getPaymentKeyActivityDescription(
					entityInstanceComponent.entityInstance.id,
					parseInt(
						metadataIdentifier,
						AppConstants.parseRadix),
					activityDescription,
					detailedDescription);
			}
		}

		return activityDescription
			+ metadataIdentifier;
	}

	/**
	 * Gets the claim reason description based on the reason id.
	 *
	 * @async
	 * @param {number} reasonId
	 * The reason id.
	 * @param {number} claimId
	 * The claim id.
	 * @returns {string}
	 * A claim reason description based on the sent reason id.
	 * @memberof ClaimsService
	 */
	public async getReasonDescription(
		reasonId: number,
		claimId: number): Promise<string>
	{
		const reasons: any[] =
			await this.getReasons(claimId);

		const matchedReason: any =
			reasons.find(
				(reason: any) =>
					reason.id === reasonId.toString());

		if (AnyHelper.isNull(matchedReason))
		{
			return null;
		}

		const reasonType: string =
			matchedReason.isOpenReason
				? ClaimConstants.reasonType.open
				: ClaimConstants.reasonType.close;

		return `${reasonType} Reason: ${matchedReason.description}`;
	}

	/**
	 * Gets the adjuster organization name.
	 *
	 * @async
	 * @param {number} claimId
	 * The claim id to get the organization name for.
	 * @param {number} adjusterOrganizationId
	 * The adjuster organization id.
	 * @returns {Promise<string>}
	 * An awaitable adjuster organization name matching the sent id.
	 * @memberof ClaimsService
	 */
	public async getAdjusterOrganizationName(
		claimId: number,
		adjusterOrganizationId: string): Promise<string>
	{
		const adjusterOrganizations: IEntityInstance[] =
			await this.getClaimAdjusterOrganizations(
				claimId);

		const organizationId: number =
			parseInt(
				adjusterOrganizationId,
				AppConstants.parseRadix);
		const matchingAdjusterOrganization: IEntityInstance =
			adjusterOrganizations.find(
				(organization: IEntityInstance) =>
					organization.id === organizationId);

		if (AnyHelper.isNull(matchingAdjusterOrganization))
		{
			return null;
		}

		return StringHelper.toNameString(
			null,
			null,
			matchingAdjusterOrganization.data.name.legalName);
	}

	/**
	 * Gets the vendor organization name.
	 *
	 * @async
	 * @param {number} vendorOrganizationId
	 * The vendor organization id.
	 * @returns {Promise<string>}
	 * An awaitable vendor organization name matching the sent id.
	 * @memberof ClaimsService
	 */
	public async getVendorOrganizationName(
		vendorOrganizationId: number): Promise<string>
	{
		const organizationName: string =
			await this.insuranceService.getOrganizationName(
				vendorOrganizationId,
				InsuranceConstants.insuranceEntityTypeGroups
					.claimsVendors);

		return organizationName;
	}

	/**
	 * Gets the claim owner key activity description.
	 *
	 * @async
	 * @param {number} claimOwnerId
	 * The claim owner id.
	 * @param {string} activityDescription
	 * The activity description.
	 * @param {boolean} detailedDescription
	 * If true, this will output a detailed description.
	 * @returns {Promise<string>}
	 * An awaitable key activity description ready for display.
	 * @memberof ClaimsService
	 */
	private async getClaimOwnerKeyActivityDescription(
		claimOwnerId: number,
		activityDescription: string,
		detailedDescription: boolean): Promise<string>
	{
		const userName: string =
			await this.entityService.getUserDisplayName(
				claimOwnerId);

		if (AnyHelper.isNull(userName))
		{
			return null;
		}

		const descriptionDetail: string =
			detailedDescription === true
				&& userName !== AppConstants.empty
				&& userName !== 'Unassigned'
				? ' was set as the claim owner.'
				: AppConstants.empty;

		return activityDescription
			+ userName
			+ descriptionDetail;
	}

	/**
	 * Gets the exposure key activity description.
	 *
	 * @async
	 * @param {string} activityName
	 * The key activity name.
	 * @param {string} exposureResourceIdentifier
	 * The exposure resource identifier.
	 * @param {any[]} exposures
	 * The claim exposures.
	 * @param {string} activityDescription
	 * The activity description.
	 * @param {boolean} detailedDescription
	 * If true, this will output a detailed description.
	 * @returns {Promise<string>}
	 * An awaitable key activity description ready for display.
	 * @memberof ClaimsService
	 */
	private async getExposureKeyActivityDescription(
		activityName: string,
		exposureResourceIdentifier: string,
		exposures: any[],
		activityDescription: string,
		detailedDescription: boolean): Promise<string>
	{
		const matchingExposure: any =
			exposures
				.find(
					(exposure: any) =>
						exposure.resourceIdentifier ===
							exposureResourceIdentifier);

		if (AnyHelper.isNull(matchingExposure))
		{
			return null;
		}

		const activityDescriptions: any =
			{
				[ClaimConstants.keyActivities.exposureAdded]:
					'Added ',
				[ClaimConstants.keyActivities.exposureClosed]:
					'Closed ',
				[ClaimConstants.keyActivities.exposureReopened]:
					'Reopened '
			};

		const descriptionDetail: string =
			activityDescriptions[activityName] ?? AppConstants.empty;

		return activityDescription
			+ (detailedDescription === true
				? descriptionDetail
					+ `${matchingExposure.coverage} `
						+ `${matchingExposure.level} level exposure.`
				: `${matchingExposure.level} - `
					+ `${matchingExposure.coverage}`);
	}

	/**
	 * Gets the payment key activity description.
	 *
	 * @async
	 * @param {number} claimInstanceId
	 * The claim instance id.
	 * @param {number} claimReasonId
	 * The claim reason id.
	 * @param {string} activityDescription
	 * The activity description.
	 * @param {boolean} detailedDescription
	 * If true, this will output a detailed description.
	 * @returns {Promise<string>}
	 * An awaitable key activity description ready for display.
	 * @memberof ClaimsService
	 */
	private async getPaymentKeyActivityDescription(
		claimInstanceId: number,
		claimReasonId: number,
		activityDescription: string,
		detailedDescription: boolean): Promise<string>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;
		const claimPayments: IEntityInstance[] =
			await this.entityInstanceApiService.getChildren(
				claimInstanceId,
				AppConstants.empty,
				AppConstants.empty,
				null,
				AppConstants.dataLimits.large,
				ClaimConstants.claimEntityTypeGroups.claimPayments)
				?? [];

		const matchingPayment: IEntityInstance =
			claimPayments
				.find(
					(claimPayment: IEntityInstance) =>
						claimPayment.id === claimReasonId);

		if (AnyHelper.isNull(matchingPayment))
		{
			return null;
		}

		return activityDescription
			+ (detailedDescription === true
				? `A ${matchingPayment.data.type} payment was `
					+ 'added to this claim with a status of '
					+ `${matchingPayment.data.status}.`
				: `${matchingPayment.data.status} `
					+ `${matchingPayment.data.type}`);
	}
}
