/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Location
} from '@angular/common';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	ActivatedRoute,
	Params,
	Router,
	UrlCreationOptions
} from '@angular/router';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityLayoutTypeApiService
} from '@api/services/entities/entity-layout-type.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityTableDirective
} from '@entity/directives/entity-table.directive';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityLayoutType
} from '@shared/interfaces/entities/entity-layout-type.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	ResolverService
} from '@shared/services/resolver.service';

/* eslint-enable max-len */

@Component({
	templateUrl: './entity-search.component.html',
	styleUrls: ['./entity-search.component.scss'],
	animations: [
		ContentAnimation
	]
})

/**
 * A class representing an instance of an entity search component.
 *
 * @export
 * @class EntitySearchComponent
 * @extends {EntityTableDirective}
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {IOwnershipGuardComponent}
 */
export class EntitySearchComponent
	extends EntityTableDirective
	implements OnInit, IOwnershipGuardComponent
{
	/**
	 * Creates an instance of an EntitySearchComponent. This component
	 * will display a filtered list of entities for navigation.
	 *
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ModuleService} moduleService
	 * The module service used to store the current module.
	 * @param {EntityService} entityService
	 * The entity service used for data lookups.
	 * @param {EntityTypeApiService} EntityTypeApiService
	 * The entity type service used for filter lookups.
	 * @param {EntityLayoutTypeApiService} entityLayoutTypeApiService
	 * The entity layout type api service used for layout lookups.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance service used to populate the entity list data.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The entity definition service used to populate the entity data.
	 * @param {Location} location
	 * The location service used to create and populate the url tree.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntitySearchComponent
	 */
	public constructor(
		public route: ActivatedRoute,
		public router: Router,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityLayoutTypeApiService: EntityLayoutTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public location: Location,
		public resolver: ResolverService)
	{
		super();
	}

	/**
	 * Gets or sets the entity search table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntitySearchComponent
	 */
	public entitySearchTableDefinitions: ICommonTable = null;

	/**
	 * Gets or sets the common table context.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof EntitySearchComponent
	 */
	public commonTableContext:
		IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the category options.
	 *
	 * @type {object[]}
	 * @memberof EntitySearchComponent
	 */
	public categoryOptions: object[] = [];

	/**
	 * Gets the available entity types.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public availableEntityTypes: IEntityType[] = [];

	/**
	 * Gets or sets the loaded entity type group.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public entityTypeGroup: string = null;

	/**
	 * Gets or sets the loaded entity definition.
	 *
	 * @type {IEntityDefinition}
	 * @memberof EntitySearchComponent
	 */
	public entityDefinition: IEntityDefinition;

	/**
	 * Gets or sets the route url entity types parameter.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public entityTypesParam: string;

	/**
	 * Gets or sets the route url entity type parameter.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public entityTypeParam: string;

	/**
	 * Gets or sets the route url entity filter parameter.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public entityFilterParam: string;

	/**
	 * Gets or sets the route url entity order by parameter.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public entityOrderByParam: string;

	/**
	 * Gets or sets the display title.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public displayTitle: string;

	/**
	 * Gets or sets the category label.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public selectedCategory: string;

	/**
	 * Gets or sets the loading categories flag.
	 *
	 * @type {boolean}
	 * @memberof EntitySearchComponent
	 */
	public loadingCategories: boolean = true;

	/**
	 * Gets or sets the summary data path available columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof EntitySearchComponent
	 */
	public summaryDataPathColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the nested parent key.
	 *
	 * @type {number}
	 * @memberof EntitySearchComponent
	 */
	public nestedParentKey: string;

	/**
	 * Gets or sets the entity row count.
	 *
	 * @type {number}
	 * @memberof EntitySearchComponent
	 */
	public entityRowCount: number = 15;

	/**
	 * Gets the page context sent to associated context utilities
	 * and menus.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof EntitySearchComponent
	 */
	public get pageContext(): IDynamicComponentContext<Component, any>
	{
		return <IDynamicComponentContext<Component, any>> {
			source: this
		};
	}

	/**
	 * Gets the loading entity type flag.
	 *
	 * @type {boolean}
	 * @memberof EntitySearchComponent
	 */
	public get loadingEntityType(): boolean
	{
		return this._loadingEntityType;
	}

	/**
	* Sets the loading entity type flag.
	*
	* @param {boolean} value
	* The value to set.
	* @memberof EntitySearchComponent
	*/
	public set loadingEntityType(
		value: boolean)
	{
		setTimeout(
			() =>
			{
				this._loadingEntityType = value;
			});
	}

	/**
	 * Gets or sets the the loading entity type flag property.
	 *
	 * @type {boolean}
	 * @memberof EntitySearchComponent
	 */
	private _loadingEntityType: boolean = true;

	/**
	 * Gets the entity types string value.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	private readonly entityTypes: string = 'entityTypes';

	/**
	 * Gets the entity types string value.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	private readonly entityType: string = 'entityType';

	/**
	 * Gets the entity filter string value.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	private readonly entityFilter: string = 'filter';

	/**
	 * Gets the entity order by string value.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	private readonly entityOrderBy: string = 'orderBy';

	/**
	 * Gets the order by group string.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	private readonly orderByGroup: string = 'Group';

	/**
	 * On initialization event.
	 * Configures this components display based on the sent route
	 * information.
	 *
	 * @async
	 * @memberof EntitySearchComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.route.queryParams.subscribe(
			async(parameters: Params) =>
			{
				const mappedRouteData: any =
					ObjectHelper.mapFromRouteData(
						parameters);

				this.entityTypesParam =
					!AnyHelper.isNullOrEmpty(mappedRouteData[this.entityTypes])
						? mappedRouteData[this.entityTypes]
						: AppConstants.empty;
				this.entityTypeParam =
					!AnyHelper.isNullOrEmpty(mappedRouteData[this.entityType])
						? mappedRouteData[this.entityType]
						: AppConstants.empty;
				this.entityFilterParam =
					!AnyHelper.isNullOrEmpty(mappedRouteData[this.entityFilter])
						? mappedRouteData[this.entityFilter]
						: AppConstants.empty;
				this.entityOrderByParam =
					!AnyHelper.isNullOrEmpty(
						mappedRouteData[this.entityOrderBy])
						? mappedRouteData[this.entityOrderBy]
						: `Id ${AppConstants.sortDirections.descending}`;
			});

		this.setFilterKeywordString(this.entityFilterParam);
		await this.setCategories();
		this.setCategoryLabel(this.entityTypeGroup);
		this.setupTableDefinitions();
		this.updateUrlQuery();
	}

	/**
	 * Implements the ownership guard interface.
	 * This will calculate page ownership permissions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not access is allowed to this page.
	 * @memberof EntitySearchComponent
	 */
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		if (this.availableEntityTypes.length === 0)
		{
			return false;
		}

		const fullEntityLayoutType: IEntityLayoutType =
			await this.entityLayoutTypeApiService.getSingleQueryResult(
				AppConstants.commonProperties.name
					+ ` eq '${AppConstants.layoutTypes.full}'`,
				AppConstants.empty,
				true);

		if (AnyHelper.isNull(fullEntityLayoutType))
		{
			return false;
		}

		const initialPromiseArray: Promise<any>[] = [];
		this.availableEntityTypes.forEach(
			(entityType: IEntityType) =>
			{
				initialPromiseArray.push(
					this.entityService.verifyEntityTypeAccess(
						entityType,
						fullEntityLayoutType));
			});

		const allowedEntities: boolean[] =
			await Promise.all(initialPromiseArray);

		return allowedEntities.some(
			(allowed: boolean) =>
				allowed === true);
	}

	/**
	 * Sets the categories properties which defines
	 * the category label and options to be displayed in
	 * the search filter component.
	 *
	 * @async
	 * @memberof EntitySearchComponent
	 */
	public async setCategories(): Promise<void>
	{
		const originalTypeParameter: string =
			this.entityTypesParam;
		this.availableEntityTypes =
			this.entityTypesParam === AppConstants.empty
				? await this.entityTypeApiService
					.query(
						AppConstants.empty,
						this.orderByGroup,
						null,
						100)
				: await this.entityTypeApiService
					.query(
						ApiFilterHelper.getWildcardFilter(
							'Group',
							this.entityTypesParam),
						this.orderByGroup,
						null,
						100);

		if (this.availableEntityTypes.length > 1)
		{
			// populate the category options with the existing operation groups.
			this.availableEntityTypes.forEach(
				(entityType) =>
				{
					this.categoryOptions.push(
						{
							value: entityType.group,
							label: StringHelper.beforeCapitalSpaces(
								StringHelper.getLastSplitValue(
									entityType.name,
									AppConstants.characters.period))
						});
				});

			// Set group and name if it was previously loaded.
			if (!AnyHelper.isNullOrEmpty(this.entityTypeParam))
			{
				const entityType =
					await this.entityTypeApiService
						.query(
							`Name eq '${this.entityTypeParam}'`,
							AppConstants.empty);

				this.entityTypeGroup = entityType[0].group;
				this.selectedCategory = entityType[0].group;
				this.entityInstanceApiService.entityInstanceTypeGroup =
					this.selectedCategory;
				await this.setDynamicColumns();
				this.loadingEntityType = false;
			}
		}
		else if (this.availableEntityTypes.length === 1)
		{
			this.entityTypeGroup = this.entityTypesParam;
			this.categoryOptions.push(
				{
					value: this.availableEntityTypes[0].group,
					label: StringHelper.beforeCapitalSpaces(
						StringHelper.getLastSplitValue(
							this.availableEntityTypes[0].name,
							AppConstants.characters.period))
				});
			this.entityTypeParam = this.availableEntityTypes[0].name;
			this.selectedCategory = this.availableEntityTypes[0].group;
			this.entityInstanceApiService.entityInstanceTypeGroup =
				this.selectedCategory;
			await this.setDynamicColumns();
			this.loadingEntityType = false;
		}
		else
		{
			this.entityTypeGroup = AppConstants.empty;
			this.entityTypesParam = AppConstants.empty;

			this.categoryOptions.push(
				{
					value: AppConstants.empty,
					label: AppConstants.empty
				});
		}

		if (!await this.isPageOwnershipAllowed())
		{
			EventHelper.dispatchNavigateToAccessDeniedEvent(
				this.location.path(),
				[
					'Entity.Type',
					'Entity.Version',
					'Entity.Layout'
				],
				'Access is required to at least one entity type and version. '
					+ (AnyHelper.isNullOrWhitespace(originalTypeParameter)
						? AppConstants.empty
						: `The type selection of ${originalTypeParameter} `
							+ 'did not return any available entities.'));

			return;
		}

		this.loadingCategories = false;
	}

	/**
	 * Sets the definitions for the entity search common table
	 *
	 * @memberof EntitySearchComponent
	 */
	public setupTableDefinitions(): void
	{
		this.entitySearchTableDefinitions =
			{
				tableTitle:
					AnyHelper.isNullOrWhitespace(this.entityTypeGroup)
						? 'Entities'
						: StringHelper.beforeCapitalSpaces(
							StringHelper.getLastSplitValue(
								this.entityTypeGroup,
								AppConstants.characters.period)),
				hideTableTitle: true,
				fullPageReservedHeight:
					AppConstants.staticLayoutSizes.tableSearchHeight,
				objectSearch: {
					filter: this.entityFilterParam,
					orderBy: this.entityOrderByParam,
					offset: 0,
					limit: 30,
					virtualIndex: 0,
					virtualPageSize: this.entityRowCount
				},
				apiPromise:
					async(objectSearch: IObjectSearch) =>
					{
						this.entityInstanceApiService.entityInstanceTypeGroup =
								this.entityTypeGroup;

						return this.entityInstanceApiService
							.query(
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit);
					},
				availableColumns: this.summaryDataPathColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				actions: {
					filter: {
						considerFilteringResults: () =>
						{
							document.getElementById(
								AppConstants.commonTableActions.filterInput)
								.focus();
						}
					},
					view: {
						disabledExpandItem: true,
						items: [
							{
								command: async() =>
								{
									this.router.navigate(
										[
											await this.getContextMenuModule() +
												'/entities',
											this.entityTypeGroup,
											AppConstants.viewTypes.edit,
											this.commonTableContext.source
												.selectedItem.id
										],
										{
											queryParams:
											{
												routeData:
													ObjectHelper.mapRouteData(
														{
															layoutType:
																AppConstants
																	.layoutTypes
																	.full
														})
											}
										});
								}
							}
						]
					}
				}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Handles the filter criteria changed event sent from the filter controls.
	 *
	 * @param {string} filterCriteria
	 * The filter criteria sent from the search filter.
	 * @memberof EntitySearchComponent
	 */
	public filterCriteriaChanged(
		filterCriteria: string): void
	{
		// Get the query string filter
		this.entityFilterParam =
			(!AnyHelper.isNullOrEmpty(filterCriteria))
				? this.getQueryStringContainsFilter(filterCriteria)
				: AppConstants.empty;

		this.updateUrlQuery();

		// Return if null or empty
		if (AnyHelper.isNullOrEmpty(this.selectedCategory)
			|| this.selectedCategory === AppConstants.undefined)
		{
			return;
		}

		this.commonTableContext.source.filterCriteriaChanged(
			this.entityFilterParam);
	}

	/**
	 * Gets the context menu module.
	 *
	 * @async
	 * @returns
	 * The context menu module string name.
	 * @memberof EntitySearchComponent
	 */
	public async getContextMenuModule(): Promise<string>
	{
		return JSON.parse(
			this.entityDefinition.jsonData).contextMenuModule;
	}

	/**
	 * Updates the url query string upon entity type group
	 * and the defined query parameters previously loaded
	 * if existing.
	 *
	 * @memberof EntitySearchComponent
	 */
	public updateUrlQuery(): void
	{
		const queryParameters =
			AnyHelper.isNullOrEmpty(this.entityTypeGroup)
				? <object>
					{
						entityTypes: this.entityTypesParam
					}
				: <object>
					{
						entityTypes: this.entityTypesParam,
						entityType: this.entityTypeParam,
						filter: this.entityFilterParam,
						orderBy: this.entitySearchTableDefinitions
							.objectSearch.orderBy
					};

		this.location
			.replaceState(
				this.router
					.createUrlTree(
						[],
						<UrlCreationOptions>
						{
							replaceUrl: true,
							queryParams: {
								routeData:
									ObjectHelper.mapRouteData(queryParameters)
							}
						})
					.toString());
	}

	/**
	 * Sets the columns object array based on the available
	 * data keys.
	 *
	 * @memberof EntitySearchComponent
	 */
	public async setDynamicColumns(): Promise<void>
	{
		const entityType: IEntityType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`Group eq '${this.entityTypeGroup}'`,
					AppConstants.empty);
		this.entityDefinition =
			await this.entityDefinitionApiService
				.getSingleQueryResult(
					`TypeId eq ${entityType.id}`,
					AppConstants.empty);

		this.summaryDataPathColumns =
			await this.entityService.getDynamicSummaryColumns(
				this.entityTypeGroup,
				this.entityDefinition);

		// Start with all columns selected and in column selection mode.
		this.selectedColumns =
			this.summaryDataPathColumns;

		// If we do not have any columns, display the keyword message.
		this.displayKeywordMessage =
			this.summaryDataPathColumns.length === 0;
	}

	/**
	 * Handles the category selection change. This will
	 * update the view based on the selected type group.
	 *
	 * @async
	 * @param {string} selectedCategory
	 * The selected category string.
	 * @memberof EntitySearchComponent
	 */
	public async selectedCategoryChanged(
		selectedCategory: string): Promise<void>
	{
		this.selectedCategory = selectedCategory;
		// Restore the table definition loading state
		this.loadingTableDefinitions = true;
		this.loadingEntityType = true;

		// Set the Entity Group and Name based on the selected category
		this.entityTypeGroup = selectedCategory;
		const entityType =
			await this.entityTypeApiService
				.query(
					`Group eq '${this.entityTypeGroup}'`,
					AppConstants.empty);

		this.entityTypeParam = entityType.length > 0
			? entityType[0].name
			: AppConstants.empty;

		this.entityOrderByParam =
			`Id ${AppConstants.sortDirections.descending}`;

		this.setCategoryLabel(this.entityTypeGroup);

		// Reload the table definitions and query string
		if (AnyHelper.isNullOrEmpty(selectedCategory))
		{
			this.loadingCategories = true;
			setTimeout(() =>
			{
				this.entitySearchTableDefinitions = null;
				this.filterValue = AppConstants.empty;
				this.entityFilterParam = AppConstants.empty;
				this.loadingCategories = false;
				this.loadingTableDefinitions = false;
				this.loadingEntityType = false;
				this.updateUrlQuery();
			});
		}
		else
		{
			this.entityInstanceApiService.entityInstanceTypeGroup =
				this.selectedCategory;
			await this.setDynamicColumns();

			setTimeout(
				() =>
				{
					this.setupTableDefinitions();
					this.loadingEntityType = false;
					this.updateUrlQuery();
				});
		}

		this.entitySearchTableDefinitions
			.objectSearch.filter = this.entityFilterParam;
	}
}