/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	ClaimsService
} from '@claims/services/claims.service';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'update-claim-status-claim',
	templateUrl: './update-claim-status-claim.component.html'
})

/**
 * A component representing a wizard step for updating
 * a claim status.
 *
 * @export
 * @class UpdateClaimStatusClaimComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class UpdateClaimStatusClaimComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the update claim status
	 * claim component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @param {ClaimsService} claimsService
	 * The claims service used in this component.
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService,
		public claimsService: ClaimsService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets a client message to display if this wizard is not valid
	 * to be completed.
	 *
	 * @type {string}
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	public clientMessage: string = AppConstants.empty;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the open claim layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	public openClaimLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the close claim layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	public closeClaimLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the message that will be set if the valid wizard step check
	 * returns false.
	 *
	 * @type {string}
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	private readonly invalidWizardStepMessage: string =
		'The claim status is not allowed to be updated.';

	/**
	 * Gets or sets the claim id.
	 *
	 * @type {number}
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	private claimId: number;

	/**
	 * Gets or sets the update status to string.
	 *
	 * @type {string}
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	private updateStatusTo: string;

	/**
	 * Gets or sets the workflow action name.
	 *
	 * @type {string}
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	private workflowActionName: string;

	/**
	 * Sets the open status string.
	 *
	 * @type {string}
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	private readonly openStatus: string = 'Open';

	/**
	 * Sets the closed status string.
	 *
	 * @type {string}
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	private readonly closedStatus: string = 'Closed';

	/**
	 * Sets the select an option string.
	 *
	 * @type {string}
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	private readonly selectAnOption: string = 'Select an Option';

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.claimId =
			this.context.source.activeMenuItem
				.currentData.data.id;

		this.updateStatusTo =
			this.context.source.activeMenuItem
				.currentData.data.updateStatusTo;

		this.context.source.addToNext(this.confirmClaimStatusUpdate.bind(this));

		await this.performPostInitActions();

		await this.isWizardStepValidForDisplay(
			this.claimId,
			this.updateStatusTo);

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * This will send the update claim status event and navigate to
	 * the claim.
	 *
	 * @async
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	public async confirmClaimStatusUpdate(): Promise<void>
	{
		await this.updateClaimStatus();
		await this.navigateToClaim(
			this.claimId,
			ClaimConstants.claimEntityTypeGroups.claims
		);
	}

	/**
	 * Executes the update claim status process by executing the
	 * workflow action to update the claim status.
	 *
	 * @async
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	public async updateClaimStatus(): Promise<void>
	{
		this.workflowActionName =
			this.updateStatusTo === this.openStatus
				? ClaimConstants.workflowActions.reopenClaim
				: ClaimConstants.workflowActions.closeClaim;

		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		const queryString: string =
			this.entityInstanceApiService.formUrlParam(
				AppConstants.empty,
				{
					reason: currentData.reason,
					note: await this.formatNote(
						currentData?.note,
						currentData.reason)
				});

		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		return this.activityService.handleActivity(
			new Activity(
				this.entityInstanceApiService.executeAction(
					this.claimId,
					this.workflowActionName,
					null,
					queryString),
				'<strong>Updating Claim Status</strong>',
				'<strong>Claim Status Updated</strong>',
				'Claim status has been updated.',
				'Claim status has not been updated.'));
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @async
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * Formats the user provided data into a note.
	 *
	 * @async
	 * @param {string} note
	 * The user provided note.
	 * @param {string} reason
	 * The user selected reasons.
	 * @returns {string}
	 * The formatted note data.
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	public async formatNote(
		note: string,
		reason: string): Promise<string>
	{
		const reasons: any[] =
			this.updateStatusTo === this.openStatus
				? await this.claimsService
					.getClaimReasons(
						this.claimId,
						ClaimConstants.reasonType.open)
				: await this.claimsService
					.getClaimReasons(
						this.claimId,
						ClaimConstants.reasonType.close);

		const reasonData: any =
			reasons.find((item: any) => item.id === reason);

		const formattedNote: string =
			note
				? `${reasonData.description} - ${note}`
				: `${reasonData.description}`;

		return formattedNote;
	}

	/**
	 * This will navigate to the claim layout using the
	 * claim id provided.
	 *
	 * @async
	 * @param {number} entityId
	 * The claim entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	private async navigateToClaim(
		entityId: number,
		group: string)
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		this.openClaimLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'data.reason',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Open Reason',
						description: 'Claim reopen reason.',
						placeholder: this.selectAnOption,
						showClear: true,
						required: true,
						options:
							(await this.claimsService
								.getClaimReasons(
									Number(this.claimId),
									ClaimConstants.reasonType.open))
								?.map((item: any) =>
									<IDropdownOption>
									{
										label: item.description,
										value: item.id
									})
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.note',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Description',
						description: 'Reopen claim description.',
						placeholder: 'Reopen notes',
						rows: 1,
						gridColumns: 6
					}
				}
			];

		this.closeClaimLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'data.reason',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Close Reason',
						description: 'Claim close reason.',
						placeholder: this.selectAnOption,
						showClear: true,
						required: true,
						options:
							(await this.claimsService
								.getClaimReasons(
									Number(this.claimId),
									ClaimConstants.reasonType.close))
								?.map((item: any) =>
									<IDropdownOption>
									{
										label: item.description,
										value: item.id
									})
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.note',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Description',
						description: 'Close claim description.',
						placeholder: 'Closing notes',
						rows: 1,
						gridColumns: 6
					}
				}
			];

		this.dynamicFormlyLayout =
			this.updateStatusTo === this.openStatus
				? this.openClaimLayout
				: this.closeClaimLayout;
	}

	/**
	 * Validates the wizard step based on the component logic to
	 * confirm if this should be displayed or not.
	 *
	 * @async
	 * @param {number} claimId
	 * The claim id to validate.
	 * @param {IEntityInstance} updateStatusTo
	 * The status the exposure is updated to.
	 * @memberof UpdateClaimStatusClaimComponent
	 */
	private async isWizardStepValidForDisplay(
		claimId: number,
		updateStatusTo: string): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		const claim: IEntityInstance =
			await this.entityInstanceApiService.get(claimId);

		const claimStatus: string =
			claim.data.status;

		if (updateStatusTo === this.openStatus)
		{
			this.clientMessage =
				claimStatus === this.closedStatus
					? AppConstants.empty
					: this.invalidWizardStepMessage;

			return;
		}

		this.clientMessage =
			claimStatus !== this.closedStatus
				? AppConstants.empty
				: this.invalidWizardStepMessage;
	}
}